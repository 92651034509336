import { Grid } from '@achieve/sunbeam'
import { CardEmployeeStory } from 'components/Cards/CardEmployeeStory'
import { Typography } from 'components/Contentful'
import styles from './EmployeeStoriesGrid.module.scss'
import { get as _get } from 'lodash-es'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { PaperCarousel } from 'components/Carousels/PaperCarousel'
import Section from 'components/Section'

export function EmployeeStoriesGrid({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)
  const title = _get(content, 'fields.sectionContents[0]')
  const grid = _get(content, 'fields.sectionContents[1].fields.gridItems')

  let carouselItems = grid.map((card, id) => {
    return {
      metadata: {},
      sys: { id: `item${id}` },
      fields: {
        fieldName: `employeeCarousel-${id}`,
        uiComponent: 'Card',
        cardImage: card.fields.image,
        cardText: {
          content: [
            card.fields.eyebrow,
            card.fields.title,
            card.fields.text,
            card.fields.link.fields.linkText,
          ],
        },
        cardLink: card.fields.link,
      },
    }
  })

  const theme = { name: 'Employee Section Theme', fieldName: 'theme', theme: ['white'] }

  return (
    <Section className={styles['grid-section']}>
      {!isMobile ? (
        <>
          <Typography
            content={title}
            variant={'displayM20'}
            component="h2"
            className={styles['grid-title']}
            fontWeight="bold"
            data-testid={`employee-grid-title`}
          />
          <Grid container spacing={'32px'} className={styles['employee-card-grid']}>
            {grid.map((card, idx) => {
              return (
                <Grid item xl={4} key={idx}>
                  <CardEmployeeStory data-testid="employee-grid-card" key={idx} content={card} />
                </Grid>
              )
            })}
          </Grid>
        </>
      ) : (
        <>
          <PaperCarousel
            data-testid="employee-carousel"
            title={{ textContent: title }}
            carouselItems={carouselItems}
            theme={theme}
            idPrefix="home"
          />
        </>
      )}
    </Section>
  )
}
