import styles from './CardPressSection.module.scss'
import { Box, Grid, Button } from '@achieve/sunbeam'
import { MediaImage, Typography } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

export const CardPressSection = ({ content }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const title = content?.fields?.title
  const description = content?.fields?.description?.content[0]?.content[0]?.value
  const image = content?.fields?.image
  const downloadFile = content?.fields?.downloadFile?.fields?.file?.url

  const downloadFileAuto = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.download = `${title}.pdf`
    link.dispatchEvent(new MouseEvent('click'))
  }

  return (
    <Box className={styles.card}>
      <Grid container>
        <Grid item xs={12} lg={9} className={styles['description-box']}>
          <Box className={styles['description-image']}>
            <Box>
              <MediaImage
                className={styles.image}
                content={image}
                layout="fixed"
                objectFit="cover"
                width="279px"
                height="156px"
              />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={isMobile ? 'center' : 'initial'}
            paddingLeft={isMobile ? 0 : 4}
            mt={isMobile ? 3.75 : 0}
          >
            <Typography
              content={title}
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS10' }}
              fontWeight="medium"
              styleOverride={
                isMobile
                  ? { [BLOCKS.PARAGRAPH]: { 'text-align': 'center' } }
                  : { [BLOCKS.PARAGRAPH]: {} }
              }
            />
            <Box mt={2.5}>
              <Typography
                className={styles['description-text']}
                content={description}
                variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayXS30' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3} className={styles['box-download']}>
          <Box
            paddingLeft={isMobile ? 0 : 15}
            mt={isMobile ? 4 : 0}
            alignSelf={isMobile ? 'center' : 'end'}
          >
            <Button
              color="primary"
              onClick={() => downloadFileAuto(downloadFile)}
              variant="contained"
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
