import { useMemoizedContentGetter } from 'utils/contentful'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@achieve/sunbeam'
import Section from 'components/Section'
import { Typography, LinkButton, TypographyLongForm } from 'components/Contentful'
import { useState } from 'react'
import { TitleDivider } from 'components/TitleDivider'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { object } from 'yup'
import { faqSetSchema, richTextSchema } from 'constants/validation-types'
import { ChevronDown, ChevronUp } from 'react-feather'
import { FAQPageJsonLd } from 'next-seo'
import styles from './Faqs.module.scss'
import { get as _get } from 'lodash-es'
import { returnContentStringFaqAnswer } from 'utils/shared'

const contentSchema = object({
  faqs: object({
    faqSets: faqSetSchema,
  }),
  title: object({
    textContent: richTextSchema,
  }),
})

const contentSchemaComponent = object({
  faqs: object({
    faqSets: faqSetSchema,
  }),
  title: object({
    textContent: richTextSchema,
  }),
})

export function Faqs({ content, component }) {
  const [selected, setSelected] = useState('set1')
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [open, setOpen] = useState(false)

  const memoizedContent = useMemoizedContentGetter(content, [
    'button',
    'faqLink',
    'faqs',
    'title',
    'show',
  ])
  const toValidate = component ? contentSchemaComponent : contentSchema
  if (!toValidate.isValidSync(memoizedContent)) {
    console.warn('FaqsSection - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const { button, faqLink, faqs, title, show } = memoizedContent
  const showByDefault = _get(show, 'textContent.content[0].content[0].value')
  const showDefault = Number(showByDefault) >= 0 ? Number(showByDefault) : 5
  const sets = faqs?.faqSets
  const sectionName = content?.fields?.fieldName || 'faqs'

  const makeAccordion = (answer, question, setNumber, open) => {
    return (
      <Accordion
        className={styles['accordion']}
        data-testid="faqs-accordion"
        expanded={selected === setNumber}
        id={`faq-${setNumber}`}
        key={setNumber}
        onChange={() => setSelected(selected === setNumber ? '' : setNumber)}
        style={{ display: open ? 'block' : 'none' }}
      >
        <AccordionSummary
          className={styles['accordion-summary']}
          data-testid="faqs-question"
          expandIcon={
            <ChevronDown
              className={selected === setNumber ? styles['expand-icon-selected'] : ''}
              size={isMobile ? 24 : 32}
            />
          }
        >
          <Typography
            className={styles['accordion-summary-text']}
            content={question}
            fontWeight="medium"
            variant={isMobile ? 'displayS10' : 'displayS20'}
          />
        </AccordionSummary>
        <AccordionDetails data-testid="faqs-answer">
          <TypographyLongForm content={answer} variant="bodyS30" />
        </AccordionDetails>
      </Accordion>
    )
  }

  const makeSeoEntity = (answerData, questionData) => {
    const answer = answerData?.content
      ?.map((answerData) => returnContentStringFaqAnswer(answerData))
      ?.join(' ')
    const question = questionData?.content[0]?.content[0]?.value
    return {
      questionName: question,
      acceptedAnswerText: answer,
    }
  }

  const mapFaqSets = () => {
    let entities = []
    let faqs = []

    sets.map((set, index) => {
      const answer = set?.fields?.answer
      const question = set?.fields?.question
      const setNumber = set?.fields?.fieldName
      const entity = makeSeoEntity(answer, question)
      const accordion = makeAccordion(answer, question, setNumber, open || index + 1 <= showDefault)
      entities.push(entity)
      faqs.push(accordion)
    })

    return (
      <>
        <FAQPageJsonLd mainEntity={entities} />
        {faqs}
      </>
    )
  }

  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  return (
    <Section
      className={styles['faq-section']}
      data-testid={`${sectionName}-section`}
      id="faq-section"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 1, lg: 3 }}
        container
        data-testid="faqs-grid-container"
        justifyContent="center"
      >
        <Grid data-testid="faqs-grid-item-title" item xs={11}>
          <TitleDivider
            title={title}
            isMobile={isMobile}
            data-testid="faqs-section-title-area"
            className={styles['title']}
            variants={variant}
            disableTitleTag={true}
          />
        </Grid>
        <Grid data-testid="faqs-grid-item-accordion" item xs={11} lg={9}>
          {sets && mapFaqSets()}
        </Grid>
        {sets.length > showDefault && (
          <Grid data-testid="faqs-grid-item-button" item pt={8} xs={11}>
            <Grid container justifyContent="center">
              <Button
                variant="text"
                onClick={() => setOpen(!open)}
                endIcon={open ? <ChevronUp /> : <ChevronDown />}
              >
                {open ? 'Show less' : 'Show more'}
              </Button>
            </Grid>
          </Grid>
        )}
        {faqLink && (
          <Grid
            data-testid="faqs-grid-item-link-faq"
            item
            pt={8}
            xs={11}
            className={styles['link-container']}
          >
            <Grid container justifyContent="center">
              <LinkButton
                className={styles['button']}
                content={faqLink}
                data-testid="faqs-link-faq"
                variant="text"
                typographicOptions={{
                  fontWeight: 'bold',
                  'data-testid': 'faqs-link-faq-text',
                }}
              />
            </Grid>
          </Grid>
        )}

        {button && (
          <Grid
            data-testid="faqs-grid-item-button"
            item
            pt={8}
            xs={11}
            className={styles['link-container']}
          >
            <Grid container justifyContent="center">
              <LinkButton
                track={{
                  nav_link_section: 'Closer Statement',
                  click_type: 'Click Link',
                  click_position: '0',
                  click_id: 'faq button',
                  form_ss_amount: 1000,
                  track_event: 'internal_campaign_click',
                }}
                className={styles['button']}
                content={button}
                data-testid="faqs-button"
                typographicOptions={{
                  fontWeight: 'bold',
                  'data-testid': 'faqs-button-text',
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}
