import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImage, LinkButton } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import styles from './AboutCloserStatement.module.scss'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'

function AboutCloserStatement({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const { title, subtitle, image, button } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'image',
    'button',
  ])

  //use this once secondary button color is added to sunbeam
  // const linkText = _get(closerButton, 'linkText.content[0].content[0].value', '')

  return (
    <Section
      contain={!isMobile}
      className={styles['about-closer-statement-section']}
      data-testid="closer-statement-section"
    >
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        className={styles['outer-grid-container']}
        data-testid="closer-statement-outer-grid-container"
      >
        <Grid
          item
          xs={6}
          lg={5}
          className={styles['grid-item-image']}
          data-testid="closer-statement-grid-item-image"
        >
          <div className={styles['image-container']}>
            <MediaImage
              className={styles['image']}
              content={image?.mediaContent}
              alt={image?.mediaAltText}
              layout="responsive"
              data-testid="closer-statement-image"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          lg={7}
          className={styles['grid-item-text']}
          data-testid="closer-statement-grid-item-text"
        >
          <div className={styles['text-container']}>
            <Typography
              content={title?.textContent}
              variantOverride={{
                [BLOCKS.HEADING_2]: isMobile ? 'displayM20' : 'displayM30',
              }}
              fontWeight="bold"
              className={styles['title']}
            />
            <Typography
              content={subtitle?.textContent}
              variantOverride={{ [BLOCKS.HEADING_3]: isMobile ? 'bodyM10' : 'displayS30' }}
              className={styles['subtitle']}
              data-testid="closer-statement-subtitle"
            />
            <LinkButton
              track={{
                nav_link_section: 'About Closer Statement',
                click_type: 'Click Link',
                click_position: '0',
                click_id: 'about closer statement button',
                form_ss_amount: 1000,
                track_event: 'internal_campaign_click',
              }}
              content={button}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                fontWeight: 'bold',
              }}
              variant="outlined"
              className={styles['button']}
              data-testid="closer-statement-text-area-cta-button"
            />
          </div>
        </Grid>
      </Grid>
    </Section>
  )
}

export { AboutCloserStatement }
