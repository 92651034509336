import { BLOCKS } from '@contentful/rich-text-types'
import { Grid as SunbeamGrid, Link as SunbeamLink } from '@achieve/sunbeam'
import { MenuList, MenuItem } from '@mui/material'
import { Typography } from 'components/Contentful'
import { useState, useEffect, useRef } from 'react'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import Section from 'components/Section'
import styles from './StickySubNavWrapper.module.scss'
import { useRouter } from 'next/router'

function StickySubNavWrapper({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { sectionContents } = content?.fields || {}

  // Declare array in state for tracking position on page to highlight subnav bar
  const [intersectingElements, setIntersectingElements] = useState(
    new Array(sectionContents.length).fill(true)
  )

  // Create a ref for the subnav bar for better tabbing behavior
  const refContainer = useRef()

  // Create a ref for each major section within the policies main text area.
  const refElements = useRef()
  refElements.current = []

  // IntersectionObserver to track sections are intersecting viewport by more than {threshold}%
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionEvent, {
      rootMargin: '-150px', //viewport margin to be excluded when calculating section intersection
      threshold: 0.01, //1% of section visible in viewport is threshold to be intersecting
    })

    refElements.current.forEach((ref) => {
      observer.observe(ref)
    })
    return () => {
      refElements.current.forEach((ref) => {
        observer.unobserve(ref)
      })
    }
  }, [])

  function handleIntersectionEvent(entries) {
    const sectionObservedIndex = entries[0]?.target.getAttribute('data-section-index')
    const sectionIsIntersecting = entries[0].isIntersecting

    setIntersectingElements((prev) => {
      if (sectionObservedIndex == 0 || prev[sectionObservedIndex - 1] === false) {
        prev[sectionObservedIndex] = sectionIsIntersecting
      }
      return [...prev]
    })
  }

  // Update state on link click
  const updateIntersectingElementsOnClick = (e) => {
    let sectionClicked = 0
    refContainer.current.focus()

    if (e?.target?.getAttribute('section-index')) {
      sectionClicked = e?.target?.getAttribute('section-index')
    }

    setIntersectingElements((prev) => {
      const newState = new Array(prev.length).fill(false).fill(true, sectionClicked)
      return isMobile ? prev : newState
    })
  }

  // Dynamically add ref to section if section ref does not already exist
  const addToRefs = (observedSection) => {
    if (observedSection && !refElements.current.includes(observedSection)) {
      refElements.current.push(observedSection)
    }
  }

  const router = useRouter() || {}
  const /** @type string */ pageTestIdScope = router.pathname.split('/').pop()

  /**
   * @param {string} testId
   * @param {string} scope
   * @returns {string}
   */
  const makeTestId = (testId, scope) => {
    if (scope) return `${scope}-${testId}`
    return testId
  }

  const subNavMenuItemCreator = (
    sectionContents,
    intersectingElements,
    updateIntersectingElementsOnClick
  ) => {
    // Find the first section(closest to top of page) that is intersecting the screen area
    const minIntersectingElementIndex = intersectingElements.findIndex((el) => el)
    return (sectionContents || []).map((content, index) => {
      const sectionContentId = content?.fields?.name.replaceAll(' ', '-')
      const sectionContentName = content?.fields?.name

      return (
        <MenuItem
          key={content.sys.id}
          className={styles['item']}
          tabIndex={-1}
          onClick={updateIntersectingElementsOnClick}
          data-testid={makeTestId(`navbar-item-${index}`, pageTestIdScope)}
        >
          <SunbeamLink
            section-index={index}
            variant={isMobile ? 'displayS10' : 'displayS20'}
            href={`#${sectionContentId}`}
            //if mobile view, always highlight first navbar option, else top intersecting section
            data-active={isMobile ? index === 0 : index === minIntersectingElementIndex}
            aria-current={index === minIntersectingElementIndex}
            data-testid={makeTestId(
              `vertical-nav-${sectionContentName}-${content.sys.id}`,
              pageTestIdScope
            )}
          >
            {sectionContentName}
          </SunbeamLink>
        </MenuItem>
      )
    })
  }

  return (
    <Section
      className={styles['body-section']}
      data-testid={makeTestId('body-section', pageTestIdScope)}
    >
      <SunbeamGrid container data-testid={makeTestId('body-outer-grid-container', pageTestIdScope)}>
        <SunbeamGrid
          item
          xs={12}
          lg={4}
          data-testid={makeTestId('body-outer-grid-container', pageTestIdScope)}
        >
          <MenuList
            className={styles['vertical-menu']}
            ref={refContainer}
            tabIndex={0}
            data-testid={makeTestId('vertical-nav-menu-container', pageTestIdScope)}
          >
            {subNavMenuItemCreator(
              sectionContents,
              intersectingElements,
              updateIntersectingElementsOnClick
            )}
          </MenuList>
        </SunbeamGrid>
        <SunbeamGrid item xs={12} lg={8}>
          {(sectionContents || []).map((sectionContent, index) => {
            return (
              <div
                ref={addToRefs}
                className={styles['body-text-section']}
                id={sectionContent.fields.name.replaceAll(' ', '-')}
                data-section-index={index}
                key={sectionContent?.sys?.id}
                data-testid={makeTestId(
                  `subsection-container-${sectionContent.fields.name.replaceAll(' ', '-')}`,
                  pageTestIdScope
                )}
              >
                <Typography
                  className={styles['table-custom']}
                  variantOverride={{
                    [BLOCKS.HEADING_2]: isMobile ? 'displayS30' : 'displayM20',
                    [BLOCKS.HEADING_3]: isMobile ? 'displayS10' : 'displayS30',
                    [BLOCKS.HEADING_4]: isMobile ? 'displayXS30' : 'displayS10',
                    [BLOCKS.PARAGRAPH]: 'bodyS30',
                  }}
                  content={sectionContent}
                  styleOverride={{
                    [BLOCKS.HEADING_2]: { marginBottom: '16px' },
                    [BLOCKS.HEADING_3]: { marginBottom: '36px', marginTop: '36px' },
                    [BLOCKS.HEADING_4]: { marginBottom: '36px', marginTop: '36px' },
                    [BLOCKS.PARAGRAPH]: { marginBottom: '16px' },
                  }}
                />
              </div>
            )
          })}
        </SunbeamGrid>
      </SunbeamGrid>
    </Section>
  )
}

export { StickySubNavWrapper }
