import { Grid } from '@achieve/sunbeam'
import { CardProduct } from 'components/Cards/CardProduct'

/**
 * IN PROGRESS COMPONENT: This currenlty only emulates the `ThreeProductCard` component and is being
 * reworked with a different approach to the carousel logic.
 */
function CardCarousel({ content = {}, isMobile = true }) {
  return (
    <>
      {content && (
        <div data-testid="card-carousel">
          <Grid
            container
            justifyContent={{ xxs: 'center' }}
            alignItems={{ xs: 'stretch' }}
            spacing={isMobile ? '24px' : '32px'}
            data-testid="home-product-card-section-card-grid"
          >
            {(content.gridItems || []).map((gridContent, index) => {
              return (
                <Grid
                  item
                  xxs={12}
                  sm={9}
                  md={6}
                  lg={4}
                  key={`${gridContent?.sys?.id}-${index}`}
                  data-testid={`home-product-card-section-card-grid-item-${index}`}
                >
                  <CardProduct
                    content={gridContent}
                    isMobile={isMobile}
                    data-testid={`home-product-card-section-card-${index}`}
                  />
                </Grid>
              )
            })}
          </Grid>
        </div>
      )}
    </>
  )
}

export { CardCarousel }
