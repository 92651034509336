import { Typography } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { useRouter } from 'next/router'
import { formatPublishDate } from 'utils/conversions'
import { STORY_TYPES } from 'constants/storyTypes'
import { removePageFromQuery } from 'utils/next/routes'
import { Pill } from 'components/Pill'
import { PRESS_ROUTE } from 'constants/navigation'
import { ExternalLink } from 'react-feather'

import styles from 'components/Cards/CardPress/CardPress.module.scss'

function CardPress({ article, showPill }) {
  let { storyType, title, publishDate, slug, externalLink } = article

  if (title.length > 60) {
    title = title.substring(0, 60) + '...'
  }

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const router = useRouter()

  const removeQueryAndReturnLink = () => {
    return (
      <div
        className={styles['link-container']}
        onClick={() => {
          if (router.query?.page) delete router.query.page
          router.replace(router, null, { shallow: true })
        }}
      >
        <AchieveLink
          data-testid="card-read-more-link"
          href={
            storyType === STORY_TYPES.IN_THE_NEWS && externalLink
              ? `${externalLink}`
              : `${router.pathname}/${slug}${
                  Object.keys(router.query).length === 0
                    ? ''
                    : `?${removePageFromQuery(router.query)}`
                }`
          }
          className={styles['link']}
          target={storyType === STORY_TYPES.IN_THE_NEWS ? '_blank' : '_self'}
          rel={storyType === STORY_TYPES.IN_THE_NEWS ? 'noreferrer nofollow' : ''}
        >
          <span className={styles['link-icon-container']}>
            <Typography content={'Read more'} fontWeight="bold" variant="displayXS30" />
            {storyType === STORY_TYPES.IN_THE_NEWS ? (
              <ExternalLink className={styles['external-link']} />
            ) : null}
          </span>
        </AchieveLink>
      </div>
    )
  }

  const ClickableWrapper = ({ children }) => {
    return (
      /* Disable eslint complaint since '_blank' is conditionally applied */
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        className={styles['anchor-links']}
        target={storyType === STORY_TYPES.IN_THE_NEWS ? '_blank' : '_self'}
        rel={storyType === STORY_TYPES.IN_THE_NEWS ? 'noreferrer nofollow' : ''}
        href={
          storyType === STORY_TYPES.IN_THE_NEWS && externalLink
            ? `${externalLink}`
            : `${PRESS_ROUTE}/${slug}${
                Object.keys(router.query).length === 0
                  ? ''
                  : `?${removePageFromQuery(router.query)}`
              }`
        }
      >
        {children}
      </a>
    )
  }

  return (
    <Grid className={styles['card-container']}>
      <Grid className={styles['card-content']}>
        {storyType &&
          (showPill ? (
            <Pill category={storyType} article={true} pathOverride={`${PRESS_ROUTE}/`} />
          ) : (
            <Typography
              content={storyType}
              variant="displayXS30"
              className={styles['card-story-type']}
              data-testid={`card-story-type`}
            />
          ))}
        {title && (
          <ClickableWrapper data-testid="card-title-link">
            <Typography
              content={title}
              variant={isMobile ? 'displayS10' : 'displayS20'}
              component="h3"
              className={styles['card-title']}
              fontWeight="bold"
              data-testid={`card-title`}
            />
          </ClickableWrapper>
        )}
        {publishDate && (
          <Typography
            content={formatPublishDate(publishDate)}
            variant="bodyS30"
            className={styles['card-date']}
            fontWeight="regular"
            data-testid={`card-text`}
          />
        )}
        {slug && removeQueryAndReturnLink()}
      </Grid>
    </Grid>
  )
}

export { CardPress }
