import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard } from 'components/Contentful'
import Section from 'components/Section'
import styles from './CloserStatementApp.module.scss'
import { Box, Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink'
import { useEffect, useState } from 'react'
import useIsSsr from 'hooks/useIsSsr'
import { actualDevice, DEVICES } from 'utils/shared'

function CloserStatementApp({ content }) {
  const isSsr = useIsSsr()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [device, setDevice] = useState(null)

  const { title, description, image, button_google, button_apple, image_google, image_apple } =
    useMemoizedContentGetter(content, [
      'title',
      'description',
      'image',
      'button_google',
      'button_apple',
      'image_google',
      'image_apple',
    ])

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])

  return (
    <Section
      className={styles['closer-statement-section']}
      data-testid="closer-statement-section"
      contain={false}
    >
      <Box className={styles['closer-statement-box']}>
        <Box className={styles['closer-statement-content']}>
          <Typography
            content={title?.textContent}
            variant={isMobile ? 'displayM20' : 'displayM30'}
            fontWeight="bold"
            className={styles['title']}
          />
          <Typography
            content={description?.textContent}
            variant={isMobile ? 'bodyM10' : 'bodyS40'}
            fontWeight="regular"
            className={styles['description']}
          />
          <Grid className={styles['buttons']} container>
            {(device === DEVICES.desktop || device === DEVICES.ios) && image_apple && (
              <AchieveLink href={button_apple?.linkHref} data-testid="molo-hero-app-store-btn">
                <div className={styles['button-apple']}>
                  <MediaImageStandard content={image_apple?.mediaContent} />
                </div>
              </AchieveLink>
            )}
            {(device === DEVICES.desktop || device === DEVICES.android) && image_google && (
              <AchieveLink href={button_google?.linkHref} data-testid="molo-hero-google-play-btn">
                <div className={styles['button-google']}>
                  <MediaImageStandard content={image_google?.mediaContent} />
                </div>
              </AchieveLink>
            )}
          </Grid>
        </Box>
        <Box className={styles['closer-statement-image-bg']}>
          <MediaImageStandard
            content={image?.mediaContent}
            layout={isMobile ? 'fill' : 'fixed'}
            objectFit={'contain'}
            objectPosition="center bottom"
            height={isMobile ? 250 : 440}
          />
        </Box>
      </Box>
    </Section>
  )
}

export { CloserStatementApp }
