import {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsLeadership,
  AboutUsHero,
} from 'pageSections/About'
import { CardGrid } from 'pageSections/CardGrid'
import { CloserStatement } from 'pageSections/CloserStatement'
import {
  FeaturedStory,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
} from 'pageSections/MemberStories'
import { Faqs } from 'pageSections/Faqs'
import { HomeHero, HomeHeroV2, HomeMadLib } from 'pageSections/Home'
import { PoliciesHero, PoliciesBody } from 'pageSections/Policies'
import { ProblemStatement } from 'pageSections/ProblemStatement'
import { ByTheNumbers } from 'pageSections/ByTheNumbers'
import { ProductCards } from 'pageSections/ProductCards'
import { ValueProps } from 'pageSections/ValueProps'
import { PhoneAndNumbers } from 'pageSections/PhoneAndNumbers'
import { AboutUsVideo } from 'pageSections/About/AboutUsVideo'
import { MoreResourcesCarousel } from 'pageSections/MoreResourcesCarousel'
import { TestimonialCarousel } from 'pageSections/TestimonialCarousel'
import { ProductHero } from 'pageSections/ProductHero'
import { StoryBlocks } from 'pageSections/StoryBlocks/StoryBlocks'
import { StoryBlocksAuthor } from 'pageSections/StoryBlocksAuthor/StoryBlocksAuthor'
import { AddressSection } from 'pageSections/AddressSection'
import { PressHero, FeaturedPressCarousel } from 'pageSections/Press'
import { PageTitle } from 'pageSections/PageTitle'
import { StickySubNavWrapper } from 'pageSections/StickySubNavWrapper'
import { PressKitDownload } from 'pageSections/PressKitSection'
import { PaginatedStoryGrid } from 'pageSections/PaginatedStoryGrid'
import { PaginatedArticlesGrid } from 'pageSections/PaginatedArticlesGrid'
import { EmailForm } from 'pageSections/EmailForm'
import { VideoDialog } from 'components/VideoDialog'
import { HeroSection } from 'pageSections/HeroSection'
import { SmsDisclosures } from 'pageSections/SmsDisclosures'
import { MoloSection } from 'pageSections/MoloSection'
import { CloserStatementApp } from 'pageSections/CloserStatementApp'
import { TrustMarkersSection } from 'pageSections/TrustMarkersSection'
import { EmployeeStoriesGrid } from 'pageSections/EmployeeStoriesGrid'
import { EmployeeStoriesHero, EmployeeStoriesPolaroidCollage } from 'pageSections/EmployeeStories'
import { TestimonialFullBackgroundCarousel } from 'pageSections/TestimonialFullBackgroundCarousel'
import { SectionFullBackground } from 'pageSections/Employee'
import { MoloHero } from 'pageSections/MoloHero'
import { MultiSlider } from 'pageSections/MultiSlider'
import { PartnersForm } from 'pageSections/PartnersForm'

// values in the componentMap need to match dropdown options in Contentful
export const pageSectionTypes = {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsHero,
  AboutUsLeadership,
  AboutUsVideo,
  AddressSection,
  ByTheNumbers,
  CardGrid,
  CloserStatement,
  EmployeeStoriesPolaroidCollage,
  EmployeeStoriesHero,
  Faqs,
  FeaturedPressCarousel,
  FeaturedStory,
  HeroSection,
  HomeHero,
  HomeHeroV2,
  HomeMadLib,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
  MoloHero,
  MoreResourcesCarousel,
  PageTitle,
  PaginatedArticlesGrid,
  PaginatedStoryGrid,
  PoliciesBody,
  PoliciesHero,
  PressHero,
  PressKitDownload,
  ProblemStatement,
  ProductCards,
  ProductHero,
  StickySubNavWrapper,
  StoryBlocks,
  TestimonialCarousel,
  ValueProps,
  VideoDialog,
  SmsDisclosures,
  EmailForm,
  PhoneAndNumbers,
  MoloSection,
  MultiSlider,
  CloserStatementApp,
  TrustMarkersSection,
  EmployeeStoriesGrid,
  TestimonialFullBackgroundCarousel,
  SectionFullBackground,
  StoryBlocksAuthor,
  PartnersForm,
}
