import { useMemoizedContentGetter } from 'utils/contentful/useMemoizedContentGetter'
import Section from 'components/Section'
import styles from './ProductCards.module.scss'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { TitleDivider } from 'components/TitleDivider'
import { CardCarousel } from 'components/Carousels/CardCarousel'
import { ThreeProductCard } from 'components/ThreeProductCard'
import useTheme from 'hooks/useTheme'
import { get as _get } from 'lodash-es'

const CARD_CAROUSEL_MINIMUM_LENGTH = 4

function ProductCards({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const theme = useTheme()
  const { eyebrow, title, subtitle, grid } = useMemoizedContentGetter(content, [
    'eyebrow',
    'title',
    'subtitle',
    'grid',
  ])
  const nameField = _get(content, 'fields.fieldName')
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  // Cards that re in draft mode won't have the `fields` prop
  const publishedCards = (grid?.gridItems || []).filter((item) => Boolean(item.fields))

  if (!publishedCards.length) return null

  const CardLayoutComponent =
    publishedCards.length >= CARD_CAROUSEL_MINIMUM_LENGTH ? CardCarousel : ThreeProductCard

  return (
    <Section
      backgroundColor={
        nameField === 'productCardsGrays'
          ? theme?.sb?.colors?.neutral?.grey?.[8]
          : theme?.sb?.colors?.primary?.highlightBlue
      }
      className={styles['section']}
      data-testid="product-card-section"
    >
      <TitleDivider
        eyebrow={eyebrow}
        title={title}
        subtitle={subtitle}
        isMobile={isMobile}
        data-testid="product-card-section-title-area"
        variants={variant}
      />
      {grid && <CardLayoutComponent content={{ gridItems: publishedCards }} isMobile={isMobile} />}
    </Section>
  )
}

export { ProductCards }
