import React, { useEffect, useReducer } from 'react'
import Section from 'components/Section'

import { Typography } from 'components/Contentful/Typography'
import { Grid } from '@achieve/sunbeam'
import { Filter } from 'components/Filter'
import { CardPress } from 'components/Cards/CardPress'

import { useMemoizedContentGetter } from 'utils/contentful'
import { object } from 'yup'
import { richTextSchema } from 'constants/validation-types'

import styles from './PaginatedStoryGrid.module.scss'
import useViewPortSize from 'hooks/useViewportSize'

import { Pagination } from '@achieve/sunbeam'
import { useRouter } from 'next/router'
import { AchieveLink } from 'components/AchieveLink'

import { filterReducer } from 'utils/shared/filter'
import { getFilterValueByUrl } from 'utils/shared/filter'

let contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }),
})

const fetchArticles = async ({ dispatch, body }) => {
  try {
    const res = await fetch('/api/contentful/fetch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
    const contentfulJson = await res.json()
    dispatch({ type: 'SET_ARTICLES', payload: contentfulJson })
  } catch (error) {
    dispatch({ type: 'SET_ARTICLES', payload: { articles: [] } })
  }
}

function PaginatedStoryGrid({ content }) {
  const router = useRouter()

  const variant = useViewPortSize()

  let memoizedContent = useMemoizedContentGetter(content, ['title', 'filter', 'config'])

  const [{ articlesObject, filterBy, keywordsFilter, page, titleId }, dispatch] = useReducer(
    filterReducer,
    {
      articlesObject: { articles: [] },
      filterBy: '*',
      keywordsFilter: '',
      page: router.query ? router.query.page : 1,
      titleId: 'press_story_grid',
    }
  )

  useEffect(() => {
    dispatch({
      type: 'SET_FILTER_BY',
      payload: getFilterValueByUrl(router.asPath, filter.jsonContent),
    })
    dispatch({ type: 'SET_PAGE', payload: 1 })
    dispatch({ type: 'SET_TITLE', payload: `${slugifyTitleForId(title.textContent)}` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let body = {
      content_type: config.jsonContent.longFormContentType,
      pageLimit: config.jsonContent.pageLimit,
      page,
    }

    if (filterBy !== '*') {
      body.filter = filterBy
    }

    if (keywordsFilter !== '') {
      body.keywords = keywordsFilter
    }

    fetchArticles({ dispatch, body })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterBy, keywordsFilter])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }

  let { title, filter, config } = memoizedContent

  const strictTypography = {
    variant: title[`variant_${variant.breakpointLetterSize}`],
    weight: title[`weight_${variant.breakpointLetterSize}`],
  }

  const handleChangePage = (event, page) => {
    dispatch({ type: 'SET_PAGE', payload: page })
    router.push({ query: { ...router.query, page } }, null, { shallow: true, scroll: false })
  }

  const slugifyTitleForId = (title) => {
    try {
      return title?.content[0]?.content[0]?.value.toLowerCase().split(' ').join('_')
    } catch (e) {
      return 'press-story-grid-title'
    }
  }

  return (
    <>
      <Section className={styles['paginated-story-grid-section']} id="press_grid">
        <Typography
          data-testid="press-grid-title"
          id={`${slugifyTitleForId(title.textContent)}`}
          className={styles['title']}
          content={title.textContent}
          variant={strictTypography.variant}
          sx={{ margin: 0 }}
          fontWeight={strictTypography.weight}
          component={title.component}
        />
        <Grid className={styles['filter']}>
          <Filter
            data-testid="press-grid-filter"
            jsonContent={filter.jsonContent}
            name={filter.name}
            filterBy={filterBy}
            withKeywords={true}
            withUrl={true}
            config={config}
            setFilterBy={(val) => dispatch({ type: 'SET_FILTER_BY', payload: val })}
            setKeywords={(val) => dispatch({ type: 'SET_KEYWORDS', payload: val })}
          />
        </Grid>

        {articlesObject.articles && articlesObject.articles.length > 0 && (
          <>
            <Grid container className={styles['news-card-grid']} data-testid="press-grid-container">
              <Grid container spacing={3}>
                {articlesObject.articles.map((article, idx) => {
                  return (
                    <Grid item xs={12} md={6} xl={4} key={idx}>
                      <CardPress
                        data-testid="press-grid-card"
                        key={idx}
                        article={article.fields}
                        showPill={true}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            {parseInt(articlesObject.total) / parseInt(config.jsonContent.pageLimit) > 1 && (
              <Grid item className={styles['pagination']}>
                <AchieveLink href={`#${titleId}`} withNextLink>
                  <Pagination
                    color="primary"
                    className={styles['pagination-items']}
                    data-testid="press-grid-pagination"
                    count={Math.ceil(
                      parseInt(articlesObject.total) / parseInt(config.jsonContent.pageLimit)
                    )}
                    onChange={handleChangePage}
                    page={parseInt(page)}
                  />
                </AchieveLink>
              </Grid>
            )}
          </>
        )}
      </Section>
    </>
  )
}

export { PaginatedStoryGrid }
