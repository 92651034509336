import { useMemoizedContentGetter } from 'utils/contentful/useMemoizedContentGetter'
import Section from 'components/Section'
import { MadLib } from 'components/MadLib'
import styles from './HomeMadLib.module.scss'
import useTheme from 'hooks/useTheme'

export const HomeMadLib = ({ content }) => {
  // TODO Add yup validation
  const { homeMadLib } = useMemoizedContentGetter(content, ['homeMadLib'])
  const theme = useTheme()

  const {
    title,
    button,
    lineOneOptions,
    lineTwoOptions,
    defaultResult,
    results,
    resetLinkText,
    resultsMap,
    resultsSubtitle,
  } = homeMadLib

  const madLibProps = {
    title,
    button,
    lineOneOptions,
    lineTwoOptions,
    defaultResult,
    results,
    resetLinkText,
    resultsMap,
    resultsSubtitle,
  }

  return (
    <Section
      className={styles['home-mad-lib-container']}
      contain={true}
      backgroundColor={theme?.sb?.colors?.primary?.highlightBlue}
    >
      <MadLib {...madLibProps} />
    </Section>
  )
}
