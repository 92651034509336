import { useEffect, useRef, useReducer } from 'react'
import { Grid, CircularProgress } from '@achieve/sunbeam'
import { MadLibResults } from './MadLibResults'
import { MadLibForm } from './MadLibForm'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import styles from './MadLib.module.scss'
import useTheme from 'hooks/useTheme'
import useViewPortSize from 'hooks/useViewportSize'

const MAD_LIB_FORM = 'MAD_LIB_FORM'
const MAD_LIB_PROGRESS = 'MAD_LIB_PROGRESS'
const MAD_LIB_RESULTS = 'MAD_LIB_RESULTS'

const MAD_LIB_DEFAULT_CONTAINER_HEIGHT = 'auto'
const MAD_LIB_DEFAULT_ANIMATION = 'height 250ms cubic-bezier(0.4, 0, 0.2, 1)' // matches SB/MUI default animation timing
const MAD_LIB_PROGRESS_DURATION = 1000

const madLibReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_STATE':
      return action.payload
    case 'SET_LINE_ONE_VALUE':
      return { ...state, lineOneValue: action.payload }
    case 'SET_LINE_TWO_VALUE':
      return { ...state, lineTwoValue: action.payload }
    case 'SET_STEP':
      return { ...state, step: action.payload }
    case 'SET_CONTAINER_HEIGHT':
      return { ...state, containerHeight: action.payload }
    case 'SET_RESULTS_EXPANDED':
      return { ...state, mobileExpanded: action.payload }
    default:
      return state
  }
}

function MadLib({
  button,
  defaultResult,
  lineOneOptions,
  lineTwoOptions,
  resetLinkText,
  results,
  resultsMap,
  resultsSubtitle,
  title,
}) {
  const [{ containerHeight, lineOneValue, lineTwoValue, mobileExpanded, step }, dispatch] =
    useReducer(madLibReducer, {
      containerHeight: MAD_LIB_DEFAULT_CONTAINER_HEIGHT,
      lineOneValue: '',
      lineTwoValue: '',
      mobileExpanded: false,
      step: MAD_LIB_FORM,
    })

  const { screenWidth = 0 } = useViewPortSize() || {}
  const theme = useTheme()
  const layoutRef = useRef(null)
  const progressTimeoutRef = useRef(null)

  const isBusy = step === MAD_LIB_PROGRESS
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  // Height of the mad-lib-layout container div child html node. It will either be the height of the
  // form or the results page
  const innerContentHeight = layoutRef?.current?.childNodes?.[0]?.clientHeight

  useEffect(() => {
    if (lineOneOptions.dropDownOptions.length > 0 && lineTwoOptions.dropDownOptions.length > 0) {
      dispatch({
        type: 'INIT_STATE',
        payload: {
          lineOneValue: lineOneOptions.dropDownOptions[0].value,
          lineTwoValue: lineTwoOptions.dropDownOptions[0].value,
          step: MAD_LIB_FORM,
        },
      })
    }
  }, [lineOneOptions.dropDownOptions, lineTwoOptions.dropDownOptions])

  useEffect(() => {
    // The purpose of this effect is to fake the progress state, as if we're making a network call
    if (step === MAD_LIB_PROGRESS) {
      progressTimeoutRef.current = setTimeout(() => {
        dispatch({ type: 'SET_STEP', payload: MAD_LIB_RESULTS })
      }, MAD_LIB_PROGRESS_DURATION)
    } else {
      clearTimeout(progressTimeoutRef.current)
    }
  }, [step])

  useEffect(() => {
    // This effects purpose is to calculate the height of the `mad-lib-layout` child nodes for use
    // of applying the height explicitly on the outer `mad-lib-container` div. That way, css
    // transition can be applied to `height` in CSS. This should be recalculated anytime that inner
    // height changes, if a step changes, either of the mad lib form field values change, or the
    // screenWidth changes. Don't do anything during the progress state.
    !isBusy &&
      dispatch({
        type: 'SET_CONTAINER_HEIGHT',
        payload: innerContentHeight,
      })
  }, [innerContentHeight, isBusy, step, lineOneValue, lineTwoValue, screenWidth, mobileExpanded])

  const onResetClick = () => {
    if (isMobile) {
      // The distance from the top plus an additional 70 to account for the mobile header and margin
      const distanceFromTop = window.scrollY + layoutRef.current.getBoundingClientRect().top - 70
      window.scrollTo({ top: distanceFromTop, behavior: 'smooth' })
    }

    dispatch({ type: 'SET_STEP', payload: MAD_LIB_FORM })
  }

  return (
    <div
      className={styles['mad-lib-container']}
      style={{
        height: containerHeight,
        transition:
          containerHeight === MAD_LIB_DEFAULT_CONTAINER_HEIGHT ? 'none' : MAD_LIB_DEFAULT_ANIMATION,
      }}
    >
      <div className={styles['mad-lib-layout']} ref={layoutRef}>
        {isBusy ? (
          // Show progress state
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={styles['mad-lib-progress-spinner']}
          >
            <CircularProgress
              style={{
                color: isMobile
                  ? theme?.sb?.colors?.neutral?.white
                  : theme?.sb?.colors?.primary?.achieveBlue,
              }}
            />
          </Grid>
        ) : step === MAD_LIB_RESULTS ? (
          <MadLibResults
            defaultResult={defaultResult}
            isMobile={isMobile}
            lineOneOptions={lineOneOptions}
            lineTwoOptions={lineTwoOptions}
            lineOneValue={lineOneValue}
            lineTwoValue={lineTwoValue}
            mobileExpanded={mobileExpanded}
            onResetClick={onResetClick}
            onResultsToggle={(isExpanded) =>
              dispatch({ type: 'SET_RESULTS_EXPANDED', payload: isExpanded })
            }
            resetLinkText={resetLinkText}
            results={results}
            resultsMap={resultsMap}
            resultsSubtitle={resultsSubtitle}
          />
        ) : (
          // Default to the form step
          <MadLibForm
            button={button}
            isMobile={isMobile}
            lineOneOptions={lineOneOptions}
            lineOneValue={lineOneValue}
            lineTwoOptions={lineTwoOptions}
            lineTwoValue={lineTwoValue}
            onLineOneChange={(val) => dispatch({ type: 'SET_LINE_ONE_VALUE', payload: val })}
            onLineTwoChange={(val) => dispatch({ type: 'SET_LINE_TWO_VALUE', payload: val })}
            onNextClick={() => dispatch({ type: 'SET_STEP', payload: MAD_LIB_PROGRESS })}
            title={title}
          />
        )}
      </div>
    </div>
  )
}

export { MadLib }
