import styles from 'components/Cards/CardProduct/CardProduct.module.scss'
import { Typography, MediaImageStandard, LinkButton } from 'components/Contentful'
import { Paper, Button } from '@achieve/sunbeam'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { isEmpty as _isEmpty } from 'lodash-es'
import { useRouter } from 'next/router'
import { removePageFromQuery } from 'utils/next/routes'
import { LINK_UI_COMPONENTS } from 'constants/contentfulUiComponentTypes'

export const CardProduct = ({ content }) => {
  const router = useRouter()
  const { cardTitleText, cardSubtitleText, cardText, cardLink, cardImage } =
    useInlineMemoizedContentGetter(content, [
      'cardTitleText',
      'cardSubtitleText',
      'cardText',
      'cardLink',
      'cardImage',
    ])

  if (_isEmpty(content)) return null

  const cardTitleString = cardTitleText?.content[0]?.content[0]?.value

  const linkOrDownload = (linkContent) => {
    if (!linkContent) return

    if (linkContent.sys.contentType.sys.id == 'downloableAttachment') {
      linkContent = {
        linkText: linkContent.fields.description.content[0],
        linkHref: `https:${linkContent.fields.downloadFile.fields.file.url}`,
      }
      return (
        <Button
          className={styles['button']}
          track={{
            nav_link_section: 'Product Cards',
            click_type: 'Link Click',
            click_position: '0',
            click_id: 'product cards',
            form_ss_amount: 1000,
            track_event: 'internal_campaign_click',
          }}
        >
          <a
            className={styles['a']}
            href={linkContent.linkHref}
            download={linkContent.linkText}
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              content={linkContent.linkText}
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS10' }}
              fontWeight="bold"
            ></Typography>
          </a>
        </Button>
      )
    }

    if (linkContent?.fields?.fieldName == 'goToPressKit') {
      return (
        <Button className={styles['button']}>
          <a
            className={styles['a']}
            href={`${linkContent.fields.linkHref}${
              Object.keys(router.query).length === 0 ? '' : `?${removePageFromQuery(router.query)}`
            }`}
            download={linkContent.linkText}
          >
            <Typography
              content={linkContent.fields.name}
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS10' }}
              fontWeight="bold"
            ></Typography>
          </a>
        </Button>
      )
    }

    return (
      <LinkButton
        className={styles['button']}
        track={{
          nav_link_section: 'Product Cards',
          click_type: 'Link Click',
          click_position: '0',
          click_id: 'product cards',
          form_ss_amount: 1000,
          track_event: 'internal_campaign_click',
        }}
        content={linkContent}
        typographicOptions={{
          variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
          fontWeight: 'bold',
        }}
        color="primary"
        variant={
          linkContent?.fields?.uiComponent === LINK_UI_COMPONENTS.LINK
            ? LinkButton.VARIANTS.TEXT
            : LinkButton.VARIANTS.CONTAINED
        }
        fullWidth={true}
        data-testid={`home-product-cards-section-card-button-text-${cardTitleString}`}
      />
    )
  }

  return (
    <Paper
      elevation={0}
      className={styles['card']}
      data-testid={`home-product-cards-section-card-${cardTitleString}`}
    >
      {cardImage && (
        <div className={styles['card-image']}>
          <MediaImageStandard
            content={cardImage}
            width={70}
            height={70}
            layout="fill"
            objectFit="unset"
            data-testid={`home-product-cards-section-card-image-${cardTitleString}`}
          />
        </div>
      )}
      {cardTitleText && (
        <Typography
          content={cardTitleText}
          variantOverride={{ [BLOCKS.HEADING_3]: 'displayS20' }}
          fontWeight="medium"
          data-testid={`home-product-cards-section-card-title-${cardTitleString}`}
        />
      )}
      {cardSubtitleText && (
        <Typography
          content={cardSubtitleText}
          variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayXS30' }}
          className={styles['card-subtitle']}
          fontWeight="regular"
          data-testid={`home-product-cards-section-card-subtitle-${cardTitleString}`}
        />
      )}
      {cardText && (
        <Typography
          content={cardText}
          variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodyS30' }}
          className={styles['card-copy-content']}
          fontWeight="regular"
          data-testid={`home-product-cards-section-card-text-${cardTitleString}`}
        />
      )}
      {cardLink && <div className={styles['button-container']}>{linkOrDownload(cardLink)}</div>}
    </Paper>
  )
}
