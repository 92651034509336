import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { Grid } from '@achieve/sunbeam'
import { Typography, MediaImage, LinkToSection } from 'components/Contentful'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Paper } from '@mui/material'
import useTheme from 'hooks/useTheme'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './PressHero.module.scss'

function PressHero({ content }) {
  const {
    pressSectionHeroImage,
    pressSectionHeroCopy,
    pressSectionheroTitle,
    pressSectionHeroSubTitle,
    PressSectionHeroCTA,
  } = useMemoizedContentGetter(content, [
    'pressSectionHeroImage',
    'pressSectionHeroCopy',
    'pressSectionheroTitle',
    'pressSectionHeroSubTitle',
    'PressSectionHeroCTA',
  ])
  const mobileImage =
    pressSectionHeroImage?.mobileMediaContent?.fields?.file?.url ||
    pressSectionHeroImage?.mediaContent?.fields?.file?.url
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const theme = useTheme()
  return (
    <>
      {isMobile ? (
        <Section data-testid="press-hero-section" className={styles['mobile-section']}>
          <Grid container justifyContent="center">
            <Paper
              elevation={10}
              className={`${styles['paper']}`}
              style={{
                backgroundImage: `linear-gradient(353.67deg, rgba(0, 0, 0, 0.729) 25.75%, rgba(0, 0, 0, 0) 102.17%), url(https:${mobileImage})`,
              }}
              data-testid="press-hero-image"
              role="img"
              aria-label={pressSectionHeroImage?.mediaAltText}
            >
              <Typography
                content={pressSectionHeroCopy?.textContent}
                className={styles['breadcrumb-text']}
                data-testid="press-hero-breadcrumb-text"
                variant="bodyS40"
                fontWeight="bold"
              />
              <Typography
                content={pressSectionheroTitle?.textContent}
                className={styles['title-text']}
                color={theme?.sb?.colors?.neutral?.white}
                fontWeight="bold"
                variantOverride={{ [BLOCKS.HEADING_1]: 'displayM10' }}
                data-testid="press-hero-title-text"
              />

              <Typography
                content={pressSectionHeroSubTitle?.textContent}
                className={styles['subtitle-text']}
                fontWeight="regular"
                color={theme?.sb?.colors?.neutral?.white}
                data-testid="press-hero-sub-title-text"
                variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayS20' }}
              />
              <LinkToSection
                content={{
                  linkHref: PressSectionHeroCTA?.linkHref,
                  linkText:
                    PressSectionHeroCTA?.linkText?.content?.[0].content?.[0].value ||
                    'See all news releases',
                }}
                typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
                color="primary"
                variant="contained"
                data-testid="press-hero-cta"
              />
            </Paper>
          </Grid>
        </Section>
      ) : (
        <Section
          className={styles['press-hero-section']}
          childContainerClassName={styles['press-hero-container']}
        >
          <Grid container data-testid="desktop-press-hero-container">
            <Grid
              item
              xs={7}
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                content={pressSectionHeroCopy?.textContent}
                className={styles['breadcrumb-text']}
                data-testid="press-hero-breadcrumb-text"
                variant="displayS11CTA"
                fontWeight="medium"
              />
              <Typography
                content={pressSectionheroTitle?.textContent}
                variant="displayL10"
                data-testid="press-hero-title-text"
                fontWeight="bold"
                className={styles['title-text']}
              />
              <Typography
                content={pressSectionHeroSubTitle?.textContent}
                className={styles['subtitle-text']}
                variant="displayS20"
                data-testid="press-hero-sub-title-text"
              />
              <LinkToSection
                content={{
                  linkHref: PressSectionHeroCTA?.linkHref,
                  linkText:
                    PressSectionHeroCTA?.linkText?.content?.[0].content?.[0].value ||
                    'See all news releases',
                }}
                typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
                color="primary"
                variant="contained"
                data-testid="press-hero-cta"
              />
            </Grid>
            <Grid item xs={5} alignSelf="center">
              <MediaImage
                content={pressSectionHeroImage?.mediaContent}
                alt={pressSectionHeroImage?.mediaAltText}
                layout="responsive"
                objectFit="cover"
                objectPosition="center"
                width="561px"
                height="587px"
                className={styles['desktop-hero-image']}
                data-testid="press-hero-image"
              />
            </Grid>
          </Grid>
        </Section>
      )}
    </>
  )
}

export { PressHero }
