import { useMemo, useState } from 'react'
import { get as _get } from 'lodash-es'
import classNames from 'classnames'
import { Grid } from '@achieve/sunbeam'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

import styles from './EmployeeStoriesPolaroidCollage.module.scss'

function EmployeeStoriesPolaroidCollage({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { config, title, body, polaroidImages, handwrittenRules } = useMemoizedContentGetter(
    content,
    ['body', 'config', 'polaroidImages', 'title', 'handwrittenRules']
  )

  const group1 = _get(config, 'jsonContent.group1', [])
  const group2 = _get(config, 'jsonContent.group2', [])
  const rule1 = _get(handwrittenRules, 'gridItems[0]', {})
  const rule2 = _get(handwrittenRules, 'gridItems[1]', {})
  const { gridItems = [] } = polaroidImages || {}

  const group1Polaroids = useMemo(() => {
    return gridItems.filter((item) => group1.includes(item?.fields?.fieldName))
  }, [gridItems, group1])

  const group2Polaroids = useMemo(() => {
    return gridItems.filter((item) => group2.includes(item?.fields?.fieldName))
  }, [gridItems, group2])

  return (
    <Section
      contain={false}
      variant={SECTION_VARIANTS.purpleBlueGradient1}
      showPattern
      className={styles['polaroid-section']}
    >
      <Grid
        item
        container
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        className={styles['polaroid-section-content']}
      >
        {/* This container should only display on large designs where the polaroids are divided */}
        {!isMobile && (
          <Grid className={styles['polaroid-grid-item']} xs={3} item>
            <div
              className={classNames(styles['polaroid-origin'], {
                [styles['polaroid-origin-1']]: !isMobile,
              })}
            >
              {group1Polaroids.map((polaroid) => (
                <Polaroid key={polaroid?.sys?.id} polaroid={polaroid} />
              ))}
              <div className={styles['rule1']}>
                <Rule rule={rule1} />
              </div>
            </div>
          </Grid>
        )}
        <Grid
          xs={6}
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <div className={styles['body-container']}>
            {title && (
              <Typography
                content={title.textContent}
                fontWeight="bold"
                variant="displayM20"
                data-testid="hero-section-title"
              />
            )}
            {body && (
              <Typography
                content={body.textContent}
                fontWeight="regular"
                variant="bodyS30"
                data-testid="hero-section-title"
              />
            )}
          </div>
        </Grid>
        {isMobile ? (
          <Grid className={styles['polaroid-grid-item']} item>
            <div
              className={classNames(styles['polaroid-origin'], styles['polaroid-origin-mobile'])}
            >
              {/* For mobile, all polaroids will be rendered in the last div container */}
              {[...group1Polaroids, ...group2Polaroids].map((polaroid) => (
                <Polaroid key={polaroid?.sys?.id} polaroid={polaroid} />
              ))}
              <div className={styles['rule1']}>
                <Rule rule={rule1} />
              </div>
              <div className={styles['rule2']}>
                <Rule rule={rule2} />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid className={styles['polaroid-grid-item']} xs={3} item>
            <div className={classNames(styles['polaroid-origin'], styles['polaroid-origin-2'])}>
              {group2Polaroids.map((polaroid) => (
                <Polaroid key={polaroid?.sys?.id} polaroid={polaroid} />
              ))}
              <div className={styles['rule2']}>
                <Rule rule={rule2} />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

const Rule = ({ rule }) => {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className={styles['rule']} data-loaded={loaded}>
      <MediaImageStandard
        alt={rule?.fields?.mediaAltText}
        content={rule?.fields?.mediaContent}
        onLoadingComplete={() => setLoaded(true)}
        placeholder="none"
      />
    </div>
  )
}

const Polaroid = ({ polaroid }) => {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className={styles['polaroid-image']} data-loaded={loaded}>
      <MediaImageStandard
        alt={polaroid?.fields?.mediaAltText}
        content={polaroid?.fields?.mediaContent}
        onLoadingComplete={() => setLoaded(true)}
        placeholder="none"
      />
    </div>
  )
}

EmployeeStoriesPolaroidCollage.displayName = 'EmployeeStoriesPolaroidCollage'

export { EmployeeStoriesPolaroidCollage }
