import { useMemoizedContentGetter } from 'utils/contentful'
import { Box, Grid } from '@achieve/sunbeam'
import { get as _get } from 'lodash-es'
import { MediaImageStandard } from 'components/Contentful'
import Section from 'components/Section'
import { Carousel } from 'components/Carousels'
import styles from './MultiSlider.module.scss'
import { Eyebrow } from 'components/Eyebrow'

// TODO - add schema validation
function MultiSlider({ content }) {
  const { eyebrow, sliderItems } = useMemoizedContentGetter(content, ['sliderItems', 'eyebrow'])
  const carouselItems = _get(sliderItems, 'carouselItems', [])

  function Slide({ item }) {
    const slideChildItems = _get(item, 'fields.gridItems', [])
    return (
      <Grid container className={styles['multi-slider-slide']} alignItems="center">
        {slideChildItems.map((item) => (
          <Grid item key={item?.fields?.fieldName} className={styles['multi-slider-item']}>
            <MediaImageStandard
              alt={item?.fields?.mediaAltText}
              content={item?.fields?.mediaContent}
              layout="fill"
              objectFit="contain"
              width="50"
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Section
      childContainerClassName={styles['multi-slider-container']}
      className={styles['multi-slider-section']}
    >
      {eyebrow && (
        <Eyebrow
          content={eyebrow?.textContent}
          variant="displayXS30"
          fontWeight="bold"
          component="h3"
        />
      )}
      <Box className={styles['multi-slider-container']}>
        <Carousel
          className={styles['hero-carousel-wrapper']}
          carouselItems={carouselItems}
          itemComponent={Slide}
          variant="standard"
          idPrefix={`multi-slider`}
          classNameIndicatorDots={styles['multi-slider-indicator-dots']}
          withIndicators
        />
      </Box>
    </Section>
  )
}

export { MultiSlider }
