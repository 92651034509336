import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful/Typography'
import Section from 'components/Section'
import styles from './AddressSection.module.scss'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import useTheme from 'hooks/useTheme'
import { Grid, Box } from '@achieve/sunbeam'
import FmdGoodIcon from '@mui/icons-material/FmdGood'

function AddressSection({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const { title, officeTitle, address, theme } = useMemoizedContentGetter(content, [
    'title',
    'officeTitle',
    'address',
    'theme',
  ])
  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  return (
    <Section
      className={styles['section']}
      disableGutters
      contain={true}
      backgroundColor={backgroundColor}
    >
      <div className={styles['container']}>
        {title && (
          <Typography
            data-testid="title-text"
            content={title.textContent}
            variant={isMobile ? 'displayS30' : 'displayM20'}
            fontWeight="bold"
            className={styles['title']}
          />
        )}
        {officeTitle && (
          <Grid item sm={10} md={8} lg={12} className={styles['card-grid']}>
            <Box>
              <FmdGoodIcon className={styles['icon']} />
            </Box>
            <Box>
              <Typography
                data-testid="subtitle-text"
                content={officeTitle.textContent}
                variant={isMobile ? 'displayM10' : 'displayS20'}
                fontWeight={isMobile ? 'regular' : 'medium'}
                className={styles['subtitle']}
              />
            </Box>
          </Grid>
        )}

        {address && (
          <Typography
            data-testid="address-text"
            content={address.textContent}
            variant={isMobile ? 'bodyS30' : 'bodyS40'}
            fontWeight={isMobile ? 'medium' : 'regular'}
            className={styles['address']}
          />
        )}
      </div>
    </Section>
  )
}

export { AddressSection }
