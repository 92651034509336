import React, { useEffect, useReducer } from 'react'
import Section from 'components/Section'

import { Typography } from 'components/Contentful/Typography'
import { Grid } from '@achieve/sunbeam'
import { Filter } from 'components/Filter'
import { CardArticle } from 'components/Cards/CardArticle'

import { useMemoizedContentGetter } from 'utils/contentful'
import { object } from 'yup'
import { richTextSchema } from 'constants/validation-types'

import styles from './PaginatedArticlesGrid.module.scss'
import useViewPortSize from 'hooks/useViewportSize'

import { Pagination } from '@achieve/sunbeam'
import { useRouter } from 'next/router'
import { AchieveLink } from 'components/AchieveLink'
import { filterReducer, getBaseURL, getContentType } from 'utils/shared/filter'
let contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }),
})

const fetchArticles = async ({ dispatch, body }) => {
  try {
    const res = await fetch('/api/contentful/fetch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
    const contentfulJson = await res.json()
    dispatch({ type: 'SET_ARTICLES', payload: contentfulJson })
  } catch (error) {
    dispatch({ type: 'SET_ARTICLES', payload: { articles: [] } })
  }
}

function PaginatedArticlesGrid({ content }) {
  const router = useRouter()
  const categoryname = router.query.categoryname

  const variant = useViewPortSize()

  let memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'config',
    'listArticles',
    'imageDefault',
  ])

  const [{ articlesObject, filterBy, keywordsFilter, page, titleId }, dispatch] = useReducer(
    filterReducer,
    {
      articlesObject: { articles: [] },
      filterBy: categoryname ? categoryname : '*',
      keywordsFilter: '',
      page: router.query ? router.query.page : 1,
      titleId: 'article_story_grid',
    }
  )

  useEffect(() => {
    dispatch({ type: 'INITIALIZE', page: 1, title: `${slugifyTitleForId(title.textContent)}` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let body = {
      content_type: config.jsonContent.longFormContentType,
      pageLimit: config.jsonContent.pageLimit,
      page,
    }

    if (categoryname && categoryname !== '*') {
      body.category = listArticles.list[categoryname]
    }

    if (keywordsFilter !== '') {
      body.keywords = keywordsFilter
    }

    fetchArticles({ dispatch, body })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, categoryname, keywordsFilter])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }
  let { title, config, listArticles, imageDefault } = memoizedContent
  const category = {
    name: 'Article Grid Filter',
    jsonContent: { label: 'View by', options: [{ label: 'All topics', value: '*' }] },
  }
  Object.entries(listArticles.list).map((el) => {
    category.jsonContent.options.push({ label: el[1], value: el[0] })
  })

  const strictTypography = {
    variant: title[`variant_${variant.breakpointLetterSize}`],
    weight: title[`weight_${variant.breakpointLetterSize}`],
  }

  const handleChangePage = (event, page) => {
    dispatch({ type: 'SET_PAGE', payload: page })
    router.push({ query: { ...router.query, page } }, null, { shallow: true, scroll: false })
    const element = document.getElementById('article_grid')
    element.scrollIntoView()
  }

  const slugifyTitleForId = (title) => {
    try {
      return title?.content[0]?.content[0]?.value.toLowerCase().split(' ').join('_')
    } catch (e) {
      return 'article-story-grid-title'
    }
  }

  return (
    <>
      <Section id="article_grid">
        <Typography
          data-testid="article-grid-title"
          id={`${slugifyTitleForId(title.textContent)}`}
          className={styles['title']}
          content={title.textContent}
          variant={strictTypography.variant}
          sx={{ margin: 0 }}
          fontWeight={strictTypography.weight}
          component={title.component}
        />
        <Grid className={styles['filter']}>
          <Filter
            data-testid="article-grid-filter"
            jsonContent={category.jsonContent}
            name={category.name}
            filterBy={filterBy}
            withKeywords={true}
            config={config}
            setFilterBy={(val) => dispatch({ type: 'SET_FILTER_BY', payload: val })}
            setKeywords={(val) => dispatch({ type: 'SET_KEYWORDS', payload: val })}
          />
        </Grid>

        {articlesObject.articles && articlesObject.articles.length > 0 && (
          <>
            <Grid container className={styles['news-card-grid']}>
              <Grid container spacing={3}>
                {articlesObject.articles.map((article, idx) => {
                  return (
                    <Grid item xs={12} md={6} xl={4} key={idx}>
                      <CardArticle
                        data-testid="article-grid-card"
                        key={idx}
                        article={article.fields}
                        baseUrl={getBaseURL(config)}
                        contentType={getContentType(config)}
                        imageDefault={imageDefault}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            {parseInt(articlesObject.total) / parseInt(config.jsonContent.pageLimit) > 1 && (
              <Grid item className={styles['pagination']}>
                <AchieveLink href={`#${titleId}`} withNextLink>
                  <Pagination
                    color="primary"
                    className={styles['pagination-items']}
                    data-testid="article-grid-pagination"
                    count={Math.ceil(
                      parseInt(articlesObject.total) / parseInt(config.jsonContent.pageLimit)
                    )}
                    onChange={handleChangePage}
                    page={parseInt(page)}
                  />
                </AchieveLink>
              </Grid>
            )}
          </>
        )}
      </Section>
    </>
  )
}

export { PaginatedArticlesGrid }
