import { Typography, MediaImageStandard } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import styles from './CardArticle.module.scss'
import { limitStringLength } from 'utils/shared'
import { getSlug } from 'utils/conversions'

function CardArticle({ article, baseUrl = 'learn', imageDefault }) {
  const { mainImage, title, slug, primaryCategory, mainBody } = article

  let subTitleText = mainBody?.content[0]?.content[0]?.value
    ? mainBody?.content[0]?.content[0]?.value
    : null

  let shortSubTitle = null

  if (subTitleText) {
    shortSubTitle = limitStringLength(subTitleText, 110)
  }

  const primaryCategorySlug = getSlug(primaryCategory)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const ClickableWrapper = ({ children }) => {
    return (
      <AchieveLink
        className={styles['anchor-links']}
        href={`/${baseUrl}/${primaryCategorySlug}/${slug}`}
      >
        {children}
      </AchieveLink>
    )
  }

  return (
    <Grid className={styles['card-container']}>
      {(mainImage || imageDefault) && (
        <ClickableWrapper>
          <Grid className={styles['image-container']}>
            <MediaImageStandard
              content={mainImage?.fields?.mediaContent || imageDefault?.mediaContent}
              layout="fill"
              height={isMobile ? 117 : 215}
              width={isMobile ? 312 : 390}
            />
          </Grid>
        </ClickableWrapper>
      )}
      <Grid className={styles['card-content']}>
        {title && (
          <ClickableWrapper>
            <Typography
              content={title}
              variant={isMobile ? 'displayS10' : 'displayS20'}
              component="h3"
              className={styles['card-title']}
              fontWeight="bold"
              data-testid={`card-title`}
            />
          </ClickableWrapper>
        )}
        {!isMobile && shortSubTitle && (
          <Typography
            content={shortSubTitle}
            variant="bodyS40"
            className={styles['card-date']}
            fontWeight="regular"
            data-testid={`card-text`}
          />
        )}
        {slug && (
          <div className={styles['link-container']}>
            <AchieveLink
              href={`/${baseUrl}/${primaryCategorySlug}/${slug}`}
              className={styles['link']}
            >
              <Typography content={'Read the article'} fontWeight="bold" variant="displayS10" />
            </AchieveLink>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export { CardArticle }
