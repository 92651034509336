import { Typography as SunbeamTypography } from '@achieve/sunbeam'
import { MadLibSelect } from './MadLibSelect'
import { Typography } from 'components/Contentful'

import styles from './MadLib.module.scss'
import { Button } from 'components/Button'
import { getValuesFromOptions } from 'utils/conversions'

function MadLibForm({
  title,
  isMobile,
  lineOneOptions,
  lineOneValue,
  lineTwoOptions,
  lineTwoValue,
  button,
  onLineOneChange,
  onLineTwoChange,
  onNextClick,
}) {
  return (
    <div className={styles['mad-lib-form']} data-testid="mad-lib-container">
      <Typography
        content={title}
        className={styles['mad-lib-title']}
        variant={!isMobile ? 'displayM20' : 'displayM10'}
        fontWeight="bold"
      />
      <div className={styles['mad-lib-row']}>
        {/* NOTE: The apostrophe's don't show when rendering the lineOneText and lintTwoText from contentful */}
        <SunbeamTypography
          variant={!isMobile ? 'displayM20' : 'displayS20'}
          className={styles['mad-lib-leading-text']}
          data-testid="mad-lib-title-one"
        >
          {lineOneOptions?.leadingText}
        </SunbeamTypography>
        <MadLibSelect
          isMobile={isMobile}
          options={lineOneOptions.dropDownOptions}
          value={lineOneValue}
          onChange={(val) => onLineOneChange(val)}
          dataTestId="mad-lib-select-one"
        />
      </div>
      <div className={styles['mad-lib-row']}>
        <SunbeamTypography
          variant={!isMobile ? 'displayM20' : 'displayS20'}
          className={styles['mad-lib-leading-text']}
          data-testid="mad-lib-title-two"
        >
          {lineTwoOptions?.leadingText}
        </SunbeamTypography>
        <MadLibSelect
          isMobile={isMobile}
          options={lineTwoOptions.dropDownOptions}
          value={lineTwoValue}
          onChange={(val) => onLineTwoChange(val)}
          dataTestId="mad-lib-select-two"
        />
      </div>
      <div className={styles['mad-lib-row']}>
        <Button
          onClick={() => onNextClick()}
          color="primary"
          variant="contained"
          size="small"
          className={styles['mad-lib-btn']}
          track={{
            nav_link_section: 'Mad Lib selection',
            click_position: '0',
            form_ss_amount: 1000,
            click_type: 'Button Click',
            click_id: 'home_mad_lib',
            track_event: 'internal_campaign_click',
            mad_lib_first_selection: getValuesFromOptions(lineOneValue, lineOneOptions),
            mad_lib_second_selection: getValuesFromOptions(lineTwoValue, lineTwoOptions),
          }}
        >
          {button?.fields?.linkText?.content?.[0].content?.[0].value || 'See my recommendations'}
        </Button>
      </div>
    </div>
  )
}

export { MadLibForm }
