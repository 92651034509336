import { TitleDivider } from 'components/TitleDivider'
import { useMemoizedContentGetter } from 'utils/contentful'
import styles from './AboutEmployeeResourceGroups.module.scss'
import { Grid } from '@achieve/sunbeam'
import Section from 'components/Section'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { CardAboutERP } from 'components/Cards'
import useTheme from 'hooks/useTheme'

function AboutEmployeeResourceGroups({ content }) {
  const { title, subtitle, grid, theme } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'grid',
    'theme',
  ])

  const [imageWidth, imageHeight] = ['310px', '220px']
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  return (
    <Section
      contain={!isMobile}
      backgroundColor={backgroundColor}
      data-testid="about-erp-section"
      className={styles['section']}
    >
      <Grid
        container
        data-testid="value-prop-grid-container"
        className={styles['outer-grid-container']}
      >
        <Grid item className={styles['grid-wrapper']}>
          <TitleDivider
            data-testid="value-prop-title-divider"
            title={title}
            subtitle={subtitle}
            isMobile={isMobile}
            variants={variant}
          />
        </Grid>
        <Grid
          data-testid="about-erp-grid-card-container"
          container
          className={styles['card-grid']}
          spacing={0}
        >
          {grid?.gridItems.map((item, idx) => (
            <Grid
              data-testid={`about-erp-grid-card-${idx}`}
              className={styles['grid-item-container']}
              item
              xs={12}
              lg={6}
              xl={4}
              key={idx}
            >
              <CardAboutERP
                content={item}
                isMobile={isMobile}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Section>
  )
}

export { AboutEmployeeResourceGroups }
