import React from 'react'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import useTheme from 'hooks/useTheme'
import { Box } from '@achieve/sunbeam'
import styles from './TrustMarkersSection.module.scss'
import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { get as _get } from 'lodash-es'

export const TrustMarkersSection = ({ content }) => {
  const theme = useTheme()
  const { title, subtitle, style, trustMarkersGrid } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'style',
    'trustMarkersGrid',
  ])
  const StylesMarkers = {
    default: 'default',
    blue1: 'blue1',
  }
  const styleSection =
    StylesMarkers[_get(style, 'textContent.content[0].content[0].value', StylesMarkers.default)]

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const backgroundSection = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return theme?.sb?.colors?.primary?.achieveBlue
      default:
        return theme?.sb?.colors?.neutral?.grey?.[8]
    }
  }
  const subtitleVariant = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'displayS10' : 'displayS20'
      default:
        return isMobile ? 'displayS30' : 'displayM20'
    }
  }
  const subtitleFontWeight = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'regular' : 'bold'
      default:
        return 'bold'
    }
  }
  const titleMarkerVariant = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'displayM10' : 'displayL10'
      default:
        return 'displayL10'
    }
  }
  const titleMarkerWeight = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'regular' : 'mediun'
      default:
        return 'bold'
    }
  }

  return (
    <Section
      backgroundColor={backgroundSection()}
      data-testid="trust-markers-section"
      disableGutters
      contain={false}
      className={styles['trust-markers-container']}
    >
      <Box className={styles['trust-markers-content']} data-marker-style={styleSection}>
        {title?.textContent && (
          <Eyebrow
            content={title?.textContent}
            className={styles['trust-markers-title']}
            variant="displayXS30"
            fontWeight="bold"
          />
        )}
        {subtitle?.textContent && (
          <Typography
            className={styles['trust-markers-subtitle']}
            content={subtitle?.textContent}
            variant={subtitleVariant()}
            fontWeight={subtitleFontWeight()}
          />
        )}
        <Box className={styles['trust-markers-markers']}>
          {trustMarkersGrid &&
            trustMarkersGrid?.gridItems?.map((marker, index) => (
              <Box className={styles['trust-markers-item']} key={'marker-' + index}>
                <Typography
                  content={marker?.fields?.title}
                  variant={titleMarkerVariant()}
                  fontWeight={titleMarkerWeight()}
                  className={styles['trust-markers-item-title']}
                />
                <Typography
                  content={marker?.fields?.subtitle}
                  variant={'bodyS30'}
                  className={styles['trust-markers-item-subtitle']}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Section>
  )
}
