import React from 'react'
import { Typography, MediaImage } from 'components/Contentful'
import { Box, Grid } from '@achieve/sunbeam'
import styles from './MediaBox.module.scss'
import { BLOCKS } from '@contentful/rich-text-types'
import { TitleDivider } from 'components/TitleDivider'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import SlideOnVisible from 'components/SlideOnVisible'

export const MediaBox = ({ title, subtitle, titles, subtitles, disclaimers, images, alts }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  // const isSmall = useViewportSmallerThan(BREAKPOINTS.md)
  const textVariantOverrideTitle = {
    [BLOCKS.PARAGRAPH]: !isMobile ? 'displayL20' : 'displayM20',
  }
  const textVariantOverrideSubtitle = {
    [BLOCKS.PARAGRAPH]: !isMobile ? 'displayS10' : 'bodyS30',
  }

  const hasContent = () => {
    if (
      !Array.isArray(titles) ||
      !Array.isArray(images) ||
      titles.length < 3 ||
      images.length < 3
    ) {
      return false
    }
    return true
  }
  const variant = {
    title: 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }
  const mediaBoxSection = (
    <>
      <Grid container justifyContent="center" spacing={{ xs: 1, sx: 0, sm: 2, md: 4, lg: 6 }}>
        <Grid
          item
          xxs={10}
          md={8}
          lg={6}
          xs={11}
          justifyContent="space-around"
          data-testid="mediabox-title"
        >
          <div className={styles['text-heading-container']}>
            <TitleDivider title={title} subtitle={subtitle} variants={variant} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        data-testid="media-box-image-item-0"
      >
        {titles.map((text, index) => {
          const image = images[index]
          const alt = alts[index]
          const subTitle = subtitles[index]
          const disclaimer = disclaimers[index]
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xxs={12}
                md={6}
                order={{ xs: index + 1, md: index % 2 ? index + 2 : index + 1 }}
              >
                <div className={styles['block-img']}>
                  <MediaImage
                    content={image}
                    alt={alt}
                    layout="fill"
                    objectFit="cover"
                    width
                    height
                    priority={false}
                  />
                </div>
              </Grid>
              <Grid
                item
                xxs={12}
                md={6}
                order={{ xs: index + 2, md: index % 2 ? index : index + 1 }}
                className={styles['blue-box']}
                data-testid={`media-box-text-item-${index}`}
              >
                <Box className={styles['box-media-text']}>
                  <SlideOnVisible
                    scrollOnMobile={false}
                    duration={700}
                    timeout={0}
                    easing={'cubic-bezier(.75,1,.83,.67)'}
                  >
                    <div>
                      <div className={styles['text-center']}>
                        <Typography content={text} variantOverride={textVariantOverrideTitle} />
                        <Typography
                          content={subTitle}
                          variantOverride={textVariantOverrideSubtitle}
                        />
                        {disclaimer && (
                          <Typography
                            content={disclaimer}
                            variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayXS10' }}
                            className={styles['disclaimer']}
                            fontWeight="light"
                          />
                        )}
                      </div>
                    </div>
                  </SlideOnVisible>
                </Box>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )

  return hasContent() ? mediaBoxSection : <></>
}
