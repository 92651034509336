import { useContext } from 'react'
import { Button as SunbeamButton } from '@achieve/sunbeam'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'

function Button(props) {
  const { dispatch } = useContext(AnalyticsContext)

  const localClickHandler = (event, track, onClick) => {
    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: { event: handleTrackAndReactEvent(event, track, onClick) },
    })
  }

  return props.defaultbutton ? (
    <button
      {...props}
      onClick={(event) => {
        localClickHandler(event, props.track, props.onClick)
      }}
    >
      {props.children}
    </button>
  ) : (
    <SunbeamButton
      {...props}
      onClick={(event) => {
        localClickHandler(event, props.track, props.onClick)
      }}
    >
      {props.children}
    </SunbeamButton>
  )
}

export { Button }
