import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import useTheme from 'hooks/useTheme'
import styles from './FeaturedPressCarousel.module.scss'
import { PRESS_ROUTE } from 'constants/navigation'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Grid, Paper } from '@achieve/sunbeam'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { BLOCKS } from '@contentful/rich-text-types'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import { formatPublishDate, getSlug } from 'utils/conversions'
import { limitStringLength } from 'utils/shared'
import { ExternalLink } from 'react-feather'

function Card({
  item: {
    fields: { title, publishDate, slug, externalLink, storyType },
  },
}) {
  // This is a new implementation from Flat Carousel
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const smallerxl = useViewportSmallerThan(BREAKPOINTS.xl)
  const titleLength = isMobile ? 30 : 43
  const shortTitle = title.length > titleLength ? title.substring(0, titleLength) + '...' : title
  const formattedDate = formatPublishDate(publishDate)
  const linkText = storyType == 'Press Release' ? 'Read the press release' : 'Read the article'
  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      spacing={{ xs: 4 }}
      className={styles['press-item']}
    >
      <Grid container item>
        <Grid item xs={12}>
          <AchieveLink
            href={externalLink ? `${externalLink}` : `${PRESS_ROUTE}/${slug}`}
            className={styles['link']}
          >
            <Typography
              content={shortTitle}
              variant="displayS20"
              className={styles['heading-text']}
              fontWeight="medium"
            />
          </AchieveLink>
        </Grid>
        {publishDate && (
          <Grid item xs={12}>
            <Typography
              content={formattedDate}
              fontWeight="regular"
              className={styles['date']}
              variant="bodyS30"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AchieveLink
            href={externalLink ? `${externalLink}` : `${PRESS_ROUTE}/${slug}`}
            className={styles['link']}
          >
            <span className={styles['link-icon-container']}>
              <Typography
                content={linkText}
                fontWeight={isMobile ? 'medium' : 'bold'}
                variant={isMobile ? 'displayXS30' : 'displayS11CTA'}
                styleOverride={{
                  [BLOCKS.PARAGRAPH]: {
                    marginTop: '1em',
                  },
                }}
              />
              {storyType == 'In The News' && <ExternalLink className={styles['external-link']} />}
            </span>
          </AchieveLink>
        </Grid>
      </Grid>
    </Grid>
  )

  return smallerxl ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

function ArticleCard({
  item: {
    fields: { title, mainBody, mainImage, slug, primaryCategory },
    sys: { contentType },
  },
  priority,
}) {
  // This is a new implementation from Flat Carousel
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const smallers = useViewportSmallerThan(BREAKPOINTS.sm)
  const isMedium = useViewportSmallerThan(BREAKPOINTS.md)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const smallerxl = useViewportSmallerThan(BREAKPOINTS.xl)
  const titleLength = !isMobile && smallerxl ? 50 : 70
  const shortTitle = title.length > titleLength ? title.substring(0, titleLength) + '...' : title
  let shortSubTitle = mainBody
  if (mainBody?.content[0]?.content[0]?.value) {
    shortSubTitle = mainBody?.content[0]?.content[0]?.value
    shortSubTitle = limitStringLength(shortSubTitle, 110)
  }
  const articleUrl =
    contentType?.sys?.id == 'corporateBlog'
      ? 'about/blog'
      : contentType?.sys?.id == 'consumerBlog'
      ? 'consumer-name'
      : 'learn'

  const primaryCategorySlug = getSlug(primaryCategory)

  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      spacing={{ xs: 4 }}
    >
      <Grid container direction="column" item xs={12} lg={7}>
        <MediaImageStandard
          content={mainImage?.fields?.mediaContent}
          layout="fill"
          height={smallers ? 150 : isMedium ? 250 : smallerxl ? 290 : 396}
          width="auto"
          objectPosition="top"
          priority={priority}
          className={styles.image}
        />
      </Grid>
      <Grid container item xs={12} lg={5}>
        <Grid item xs={12}>
          <AchieveLink
            href={`/${articleUrl}/${primaryCategorySlug}/${slug}`}
            className={styles['link']}
          >
            <Typography
              content={shortTitle}
              variant="displayS20"
              className={styles['heading-text']}
              fontWeight="medium"
            />
          </AchieveLink>
        </Grid>
        {shortSubTitle && (
          <Grid item xs={12}>
            <Typography
              content={shortSubTitle}
              fontWeight="regular"
              className={styles['supporting-text']}
              variant="bodyS30"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AchieveLink
            href={`/${articleUrl}/${primaryCategorySlug}/${slug}`}
            className={styles['link']}
          >
            <Typography
              content="Read the article"
              fontWeight="bold"
              variant={isMobile ? 'displayXS30' : 'displayS10'}
              styleOverride={{
                [BLOCKS.PARAGRAPH]: {
                  marginTop: '1em',
                },
              }}
            />
          </AchieveLink>
        </Grid>
      </Grid>
    </Grid>
  )

  return smallerxl ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

export const FeaturedPressCarousel = ({ content }) => {
  const isMobile = useViewportSmallerThan('lg')
  const sectionIdPrefix = `press-carousel-more-resources-carousel`
  const { featuredPressCarouselTitle, articleType } = useMemoizedContentGetter(content, [
    'featuredPressCarouselTitle',
    'articleType',
  ])
  const featuredArticles = !articleType?.type
    ? content?.fields?.relatedContent
    : content?.fields?.relatedContent?.filter((e) => {
        if (articleType?.filter) {
          return (
            e?.sys?.contentType?.sys?.id == articleType?.type &&
            e?.fields?.primaryCategory == articleType?.filter
          )
        }
        return e?.sys?.contentType?.sys?.id == articleType?.type
      })
  const theme = useTheme()
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM30',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }
  return (
    <>
      {featuredArticles && featuredArticles.length > 0 && (
        <Section
          backgroundColor={theme?.sb?.colors?.primary?.highlightBlue}
          className={styles['featured-article-carousel']}
          maxWidth="true"
          data-narrow="false"
          childContainerClassName={styles['no-right-padding']}
          disableGutters
        >
          <Section
            className={styles['section']}
            maxWidth="true"
            data-narrow="false"
            childContainerClassName={styles['no-right-padding']}
            disableGutters
          >
            <TitleDivider
              className={styles['paper-carousel-title-divider']}
              title={featuredPressCarouselTitle}
              isMobile={isMobile}
              data-testid={`featured-press-carousel-title`}
              variants={variant}
            />
            <Carousel
              carouselItems={featuredArticles}
              itemComponent={articleType?.type ? ArticleCard : Card}
              variant={articleType?.type ? 'preview' : 'preview-small'}
              idPrefix={sectionIdPrefix}
            />
          </Section>
        </Section>
      )}
    </>
  )
}
