import { Typography, MediaImageStandard } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import styles from './CardEmployeeStory.module.scss'

const ClickableWrapper = ({ children, href }) => {
  return (
    <AchieveLink className={styles['anchor-links']} href={href}>
      {children}
    </AchieveLink>
  )
}

export function CardEmployeeStory({ content }) {
  const { image, title, text, link, eyebrow } = content.fields
  return (
    <Grid className={styles['card-container']}>
      <ClickableWrapper href={link.fields.linkHref}>
        <Grid className={styles['image-container']}>
          <MediaImageStandard content={image} layout="fill" height={215} width={390} />
        </Grid>
      </ClickableWrapper>
      <Grid className={styles['card-content']}>
        <Typography
          content={eyebrow}
          variant={'bodyS20'}
          component="h3"
          className={styles['card-eyebrow']}
          fontWeight="medium"
          data-testid={`card-eyebrow`}
        />
        <ClickableWrapper href={link.fields.linkHref}>
          <Typography
            content={title}
            variant={'displayS20'}
            component="h3"
            className={styles['card-title']}
            fontWeight="medium"
            data-testid={`card-title`}
          />
        </ClickableWrapper>
        <Typography
          content={text}
          variant="bodyS30"
          className={styles['card-text']}
          data-testid={`card-text`}
        />

        <div className={styles['link-container']}>
          <AchieveLink href={link.fields.linkHref} className={styles['link']}>
            <Typography content={link.fields.linkText} fontWeight="bold" variant="displayS10" />
          </AchieveLink>
        </div>
      </Grid>
    </Grid>
  )
}
