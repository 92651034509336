import styles from 'components/Cards/CardValueProp/CardValueProp.module.scss'
import { CardMedia as SunbeamCardMedia, Box } from '@achieve/sunbeam'
import { MediaImage, Typography } from 'components/Contentful'
import classNames from 'classnames'

function CardValueProp({ content, breakline, isMobile, dividerOnMobile = false, pos = 0 }) {
  const { cardTitleText, cardText, cardImage } = content?.fields || {}

  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={classNames([
        dividerOnMobile && isMobile ? styles['card-divider'] : styles['card'],
        breakline && styles['breakline'],
        dividerOnMobile && isMobile && pos !== 0 && styles['card-divider-border'],
      ])}
    >
      <Box className={styles['card-img']}>
        {cardImage && (
          <SunbeamCardMedia data-testid="sunbeam-card-media">
            <MediaImage
              data-testid="card-media-image"
              layout="fixed"
              width={70}
              height={70}
              content={cardImage}
            />
          </SunbeamCardMedia>
        )}
      </Box>
      <Box className={styles['card-info']}>
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            variant={'displayS20'}
            fontWeight="medium"
            className={styles['card-text-title']}
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={styles['card-text-subtitle']}
            variant={'bodyS30'}
            component="span"
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardValueProp }
