import { PRD_ENV } from 'constants/environments'

/**
 * React hook to check if it's running in prod
 */
const useIsProduction = () => {
  return process.env.NEXT_PUBLIC_APP_ENV === PRD_ENV
}

export { useIsProduction }
