import styles from './MoloSection.module.scss'
import { useEffect, useState } from 'react'
import Section from 'components/Section'
import { get as _get } from 'lodash-es'
import { Box, Grid } from '@achieve/sunbeam'
import { MediaImage, MediaImageStandard, Typography, LinkButton } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink'
import { ArrowRight } from 'react-feather'
import SlideOnVisible from 'components/SlideOnVisible'
import { actualDevice, DEVICES } from 'utils/shared'
import useIsSsr from 'hooks/useIsSsr'
import classNames from 'classnames'

const imageVariationDefault = (imageBackground, imageTop) => {
  return (
    <Box className={styles['molo-card-image']}>
      <SlideOnVisible duration={700} timeout={300} easing={'cubic-bezier(.75,1,.83,.67)'}>
        <Box className={styles['molo-card-image-bg']}>
          <MediaImage content={imageBackground} layout="fixed" height="460" width="360" />
        </Box>
      </SlideOnVisible>
      <SlideOnVisible duration={700} timeout={300} easing={'cubic-bezier(.75,1,.83,.67)'}>
        <Box className={styles['molo-card-image-top']}>
          <MediaImage content={imageTop} layout="fixed" width="270" />
        </Box>
      </SlideOnVisible>
    </Box>
  )
}

const imageVariationReverse = (imageBackground, imageTop) => {
  return (
    <Box className={classNames(styles['molo-card-image'], styles['molo-card-image-reverse'])}>
      <SlideOnVisible duration={700} timeout={300} easing={'cubic-bezier(.75,1,.83,.67)'}>
        <Box className={styles['molo-card-image-top']}>
          <MediaImage content={imageBackground} layout="fixed" height="150" />
        </Box>
      </SlideOnVisible>
      <SlideOnVisible duration={700} timeout={300} easing={'cubic-bezier(.75,1,.83,.67)'}>
        <Box className={styles['molo-card-image-bg']}>
          <MediaImage content={imageTop} layout="fixed" width="270" />
        </Box>
      </SlideOnVisible>
    </Box>
  )
}

function makeSection(params, index, isMobile, device) {
  const title = _get(params, 'fields.title')
  const header = _get(params, 'fields.header')
  const content = _get(params, 'fields.content')
  const link = _get(params, 'fields.link.fields')
  const linkAndroid = _get(params, 'fields.linkAndroid.fields')
  const linkApple = _get(params, 'fields.linkApple.fields')
  const imageApple = _get(params, 'fields.imageApple')
  const imageAndroid = _get(params, 'fields.imageAndroid')
  const imageBackground = _get(params, 'fields.imageBackground')
  const imageTop = _get(params, 'fields.imageTop')
  const isToLeft = !(index % 2 == 0)
  const configObject = _get(params, 'fields.configObject')

  const onlyAndroidNoImage =
    device === DEVICES.android && imageAndroid == null && linkAndroid?.linkHref
  const onlyAppleNoImage = device === DEVICES.ios && imageApple == null && linkApple?.linkHref
  const AndroidWithImage =
    (device === DEVICES.desktop || device === DEVICES.android) &&
    imageAndroid &&
    linkAndroid?.linkHref
  const AppleWithImage =
    (device === DEVICES.desktop || device === DEVICES.ios) && imageApple && linkApple?.linkHref

  // configObject.backgroudType shloud handle variation by default is usisng molo variation backgroudType set to 2 is the GOOD App variation

  return (
    <Box
      className={classNames(
        styles['molo-card'],
        ...[configObject?.backgroudType == 2 ? styles['molo-card-reverse'] : null]
      )}
      key={`section-molo-${index}`}
      data-align-left={isToLeft}
    >
      <Box className={styles['molo-card-box']}>
        <Box className={styles['molo-card-text']}>
          <SlideOnVisible duration={1150} timeout={300} easing={'cubic-bezier(.75,1,.83,.67)'}>
            <div>
              <Typography
                content={title}
                className={
                  configObject?.backgroudType == 2
                    ? styles['molo-card-text-title-black']
                    : styles['molo-card-text-title']
                }
                variant={isMobile ? 'displayXS30' : 'displayXS30'}
                fontWeight={configObject?.backgroudType == 2 ? 'medium' : 'bold'}
              />
              <Typography
                content={header}
                className={styles['molo-card-text-header']}
                variant={isMobile ? 'displayS30' : 'displayM20'}
                fontWeight="bold"
              />
              <Typography
                content={content}
                className={styles['molo-card-text-content']}
                variant={isMobile ? 'bodyS30' : 'bodyS30'}
                fontWeight="regular"
              />
              {onlyAppleNoImage && (
                <LinkButton
                  target="blank"
                  variant="outlined"
                  content={linkApple}
                  className={styles['outline-link']}
                />
              )}
              {onlyAndroidNoImage && (
                <LinkButton
                  target="blank"
                  variant="outlined"
                  content={linkAndroid}
                  className={styles['outline-link']}
                />
              )}
              {link?.linkHref && (
                <AchieveLink href={link.linkHref}>
                  <div className={styles['molo-card-text-link']}>
                    <Typography content={link.linkText} fontWeight="bold" variant="displayS10" />
                    <ArrowRight className={styles['link-arrow-right']} size={22} />
                  </div>
                </AchieveLink>
              )}
              {(imageApple || imageAndroid) && (
                <Grid className={styles['buttons']} container>
                  {AndroidWithImage && (
                    <AchieveLink href={linkAndroid?.linkHref} data-testid="molo-hero-app-store-btn">
                      <div className={styles['button-google']}>
                        <MediaImageStandard content={imageAndroid} />
                      </div>
                    </AchieveLink>
                  )}

                  {AppleWithImage && (
                    <AchieveLink href={linkApple?.linkHref} data-testid="molo-hero-app-store-btn">
                      <div className={styles['button-apple']}>
                        <MediaImageStandard content={imageApple} />
                      </div>
                    </AchieveLink>
                  )}
                </Grid>
              )}
            </div>
          </SlideOnVisible>
        </Box>
      </Box>
      {configObject?.backgroudType == 2
        ? imageVariationReverse(imageBackground, imageTop)
        : imageVariationDefault(imageBackground, imageTop)}
    </Box>
  )
}

function MoloSection({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const sectionContents = _get(content, 'fields.sectionContents')
  const [device, setDevice] = useState(null)
  const isSsr = useIsSsr()

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])
  return (
    <Section className={styles['molo-section']} contain={false}>
      {sectionContents.map((data, index) => makeSection(data, index, isMobile, device))}
    </Section>
  )
}

export { MoloSection }
