import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { MediaBox } from 'components/MediaBox'
import useTheme from 'hooks/useTheme'
import { Typography } from 'components/Contentful'
import styles from './ByTheNumbers.module.scss'
import { BLOCKS } from '@contentful/rich-text-types'
import { Grid } from '@achieve/sunbeam'

export const ByTheNumbers = ({ content }) => {
  const theme = useTheme()
  const {
    heading,
    mainDisclaimer,
    subtitle,
    titleOne,
    subTitleOne,
    imageOne,
    disclaimerOne,
    titleTwo,
    subTitleTwo,
    imageTwo,
    disclaimerTwo,
    titleThree,
    subTitleThree,
    imageThree,
    disclaimerThree,
  } = useMemoizedContentGetter(content, [
    'heading',
    'mainDisclaimer',
    'subtitle',
    'titleOne',
    'subTitleOne',
    'imageOne',
    'disclaimerOne',
    'titleTwo',
    'subTitleTwo',
    'imageTwo',
    'disclaimerTwo',
    'titleThree',
    'subTitleThree',
    'imageThree',
    'disclaimerThree',
  ])
  return (
    <Section
      backgroundColor={theme?.sb?.colors?.neutral?.white}
      data-testid="home-by-the-numbers-section"
      disableGutters
      contain={false}
      className={styles['home-by-the-numbers-container']}
    >
      <MediaBox
        title={heading}
        subtitle={subtitle}
        titles={[titleOne?.textContent, titleTwo?.textContent, titleThree?.textContent]}
        subtitles={[subTitleOne?.textContent, subTitleTwo?.textContent, subTitleThree?.textContent]}
        disclaimers={[
          disclaimerOne?.textContent,
          disclaimerTwo?.textContent,
          disclaimerThree?.textContent,
        ]}
        images={[imageOne?.mediaContent, imageTwo?.mediaContent, imageThree?.mediaContent]}
        alts={[imageOne?.mediaAltText, imageTwo?.mediaAltText, imageThree?.mediaAltText]}
      />
      {mainDisclaimer && (
        <Grid container justifyContent="center">
          <Typography
            content={mainDisclaimer?.textContent}
            variantOverride={{ [BLOCKS.PARAGRAPH]: 'displayXS10' }}
            className={styles['disclaimer']}
          />
        </Grid>
      )}
    </Section>
  )
}
