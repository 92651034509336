import React, { useEffect, useReducer } from 'react'
import FormControl from '@mui/material/FormControl'
import { Box, Select, Grid, TextField, MenuItem } from '@achieve/sunbeam'
import Section from 'components/Section'
import { Button } from 'components/Button'
import { Typography } from 'components/Contentful/Typography'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { get as _get } from 'lodash-es'
import styles from './EmailForm.module.scss'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { useIsProduction } from 'hooks/useIsProduction'
import { FEATURE_TOGGLES } from 'constants/featureToggles'

function EmailForm({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  const formId = _get(content, 'fields.sectionContents[0].sys.id')
  const title = _get(content, 'fields.sectionContents[0].fields.title')
  const mailTopics = _get(content, 'fields.sectionContents[0].fields.mailTopics')
  const sentTitleContent = _get(content, 'fields.sectionContents[0].fields.sentTitle')
  const sentTitleError = _get(content, 'fields.sectionContents[0].fields.sentError')

  const enabledForm = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_CONTACT_US)

  const should_send = useIsProduction()

  const shortTopicPlaceHolder = 'Select a topic'
  const topicPlaceholder = 'Select a topic we can help you with'

  useEffect(() => {
    // Effect to initialize the topic placeholder text after first render in browser only
    dispatch({
      type: 'setField',
      fieldName: 'topic',
      fieldValue: isMobile ? shortTopicPlaceHolder : topicPlaceholder,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reducerFunction = (state, action) => {
    switch (action.type) {
      case 'setField':
        return { ...state, [action.fieldName]: action.fieldValue }
      case 'setError':
        return { ...state, [action.fieldName]: action.hasError }
      case 'setSent':
        return { ...state, sent: true, sentTitle: sentTitleContent }
      case 'setSentError':
        return { ...state, sent: true, sentTitle: sentTitleError }
      case 'setFirstCheck':
        return { ...state, firstCheck: false }
      case 'resetState':
        return { ...state, ...action.newState }
      default:
        return state
    }
  }

  const [
    {
      company,
      disabled,
      email,
      firstCheck,
      message,
      name,
      phone,
      sent,
      sentTitle,
      subject,
      topic,
      emailError,
      messageError,
      nameError,
      subjectError,
      topicError,
    },
    dispatch,
  ] = useReducer(reducerFunction, {
    company: '',
    disabled: true,
    email: '',
    firstCheck: true,
    message: '',
    name: '',
    phone: '',
    sent: false,
    sentTitle: '',
    subject: '',
    topic: '',
    emailError: true,
    messageError: true,
    nameError: true,
    subjectError: true,
    topicError: true,
  })

  const validationRegexObject = {
    name: (name) => {
      return dispatch({
        type: 'setError',
        fieldName: 'nameError',
        hasError: !/^[a-z ,.'-]+$/i.test(name),
      })
    },
    email: (email) => {
      return dispatch({
        type: 'setError',
        fieldName: 'emailError',
        hasError: !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email),
      })
    },
    message: (message) => {
      return dispatch({
        type: 'setError',
        fieldName: 'messageError',
        hasError: !/^[\s\S]{1,1000}$/g.test(message),
      })
    },
    subject: (subject) => {
      return dispatch({
        type: 'setError',
        fieldName: 'subjectError',
        hasError: !/^.{1,1000}$/g.test(subject),
      })
    },
  }

  const handleTopicChange = (event) => {
    if (disabled) {
      dispatch({ type: 'setField', fieldName: 'disabled', fieldValue: false })
    }

    dispatch({ type: 'setField', fieldName: 'topic', fieldValue: event.target.value })
    return dispatch({ type: 'setError', fieldName: 'topicError', hasError: false })
  }

  const handleInputChange = (event) => {
    dispatch({
      type: 'setField',
      fieldName: event.target.name,
      fieldValue: event.target.value,
    })
    if (['email', 'name', 'message', 'subject'].includes(event.target.name)) {
      validationRegexObject[event.target.name](event.target.value)
    }
  }

  const checkTopic = () => {
    if (topic === ``) {
      dispatch({ type: 'setError', fieldName: 'topic', hasError: true })
    }
  }

  const submitForm = () => {
    if (firstCheck) {
      dispatch({ type: 'setFirstCheck' })
    }
    checkTopic()
    sendMail()
  }

  const sendMail = async () => {
    if (!emailError && !messageError && !nameError && !topicError && !subjectError) {
      let msg = `Name: ${name}.`
      msg = company ? `${msg} Company: ${company}.` : msg
      msg = `${msg} Email: ${email}.`
      msg = phone ? `${msg} Phone: ${phone}.` : msg
      msg = `${msg} Message: ${message}.`

      let html = `Name: ${name}. <br/>`
      html = company ? `${html} Company: ${company}. <br/>` : html
      html = `${html} Email: ${email}. <br/>`
      html = phone ? `${html} Phone: ${phone}. <br/>` : html
      html = `${html} Message: ${message}. <br/>`

      const body = {
        topic: topic,
        reply_to: email,
        should_send,
        subject: subject,
        text: msg,
        html: html, // TODO - should this be different and include markup like <br> tags, etc?
        form_id: formId,
      }

      fetch('/api/email-proxy/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 201) {
          dispatch({ type: 'setSent' })
        } else {
          dispatch({ type: 'setSentError' })
        }
      })
    }
  }

  let options = mailTopics.topicList.map((topic) => {
    return topic.topic
  })

  const restartForm = () => {
    dispatch({
      type: 'resetState',
      newState: {
        company: '',
        disabled: true,
        email: '',
        firstCheck: true,
        message: '',
        name: '',
        phone: '',
        sent: false,
        sentTitle: '',
        subject: '',
        topic: isMobile ? shortTopicPlaceHolder : topicPlaceholder,
        emailError: true,
        messageError: true,
        nameError: true,
        subjectError: true,
        topicError: true,
      },
    })
  }

  return (
    enabledForm && (
      <>
        <Section id={'contact'} className={styles['section']}>
          <Typography
            className={styles['title']}
            content={title}
            fontWeight="bold"
            variant={'displayM20'}
          />
          {sent ? (
            <>
              <Typography
                content={sentTitle}
                fontWeight="medium"
                variant={'displayS20'}
                className={styles['sent-title']}
              />
              <div className={styles['button-container']}>
                <Button
                  data-testid="submit-button"
                  onClick={() => restartForm()}
                  color="primary"
                  variant="contained"
                >
                  <Typography
                    content={'Send another message'}
                    variant="displayXS30"
                    fontWeight="bold"
                  />
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography
                content={'Message Topic'}
                fontWeight="medium"
                variant={'displayS20'}
                className={styles['main-title-text']}
              />
              <Typography
                content={'This is about'}
                variant={'displayXS30'}
                className={styles['box-text']}
              />
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    value={topic}
                    onChange={handleTopicChange}
                  >
                    {disabled && (
                      <MenuItem
                        key={'first'}
                        value={isMobile ? shortTopicPlaceHolder : topicPlaceholder}
                        disabled={true}
                      >
                        <Typography
                          content={isMobile ? shortTopicPlaceHolder : topicPlaceholder}
                          className={styles['placeholder']}
                          variantOverride={{
                            [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA',
                          }}
                        />
                      </MenuItem>
                    )}
                    {options.map((menuItem, idx) => {
                      return (
                        <MenuItem key={idx} value={menuItem}>
                          <Typography
                            content={menuItem}
                            variantOverride={{
                              [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA',
                            }}
                          />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              {topic !== (isMobile ? shortTopicPlaceHolder : topicPlaceholder) && (
                <>
                  <Typography
                    content={'Your information'}
                    fontWeight="medium"
                    variant={'displayS20'}
                    className={styles['title-text']}
                  />
                  <Grid container>
                    <Grid item xs={12} xl={6} className={styles['text-box-left']}>
                      <Typography
                        content={'Name'}
                        variant={'displayXS30'}
                        className={styles['box-text']}
                      />
                      <TextField
                        data-testid="name"
                        fullWidth
                        error={nameError && !firstCheck}
                        helperText={
                          nameError && !firstCheck ? 'Please enter a first and last name' : ''
                        }
                        name="name"
                        onChange={handleInputChange}
                        className={styles['box-text']}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} className={styles['text-box-right']}>
                      <Typography
                        content={'Company (optional)'}
                        variant={'displayXS30'}
                        className={styles['box-text']}
                      />
                      <TextField
                        data-testid="company"
                        fullWidth
                        name="company"
                        onChange={handleInputChange}
                        className={styles['box-text']}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={styles['information-container']} container>
                    <Grid item xs={12} xl={6} className={styles['text-box-left']}>
                      <Typography
                        content={'Email'}
                        variant={'displayXS30'}
                        className={styles['box-text']}
                      />

                      <TextField
                        data-testid="email"
                        type={'email'}
                        fullWidth
                        helperText={
                          emailError && !firstCheck ? 'Please enter a valid email address' : ''
                        }
                        error={emailError && !firstCheck}
                        name="email"
                        onChange={handleInputChange}
                        className={styles['box-text']}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} className={styles['text-box-right']}>
                      <Typography
                        content={'Phone (optional)'}
                        variant={'displayXS30'}
                        className={styles['box-text']}
                      />
                      <TextField
                        data-testid="phone"
                        fullWidth
                        name="phone"
                        onChange={handleInputChange}
                        className={styles['box-text']}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    content={'Your message'}
                    fontWeight="medium"
                    variant={'displayS20'}
                    className={styles['title-text']}
                  />
                  <div className={styles['long-input']}>
                    <Typography
                      content={'Subject'}
                      variant={'displayXS30'}
                      className={styles['box-text']}
                    />
                    <TextField
                      data-testid="subject"
                      fullWidth
                      helperText={subjectError && !firstCheck ? 'Please enter a subject' : ''}
                      error={subjectError && !firstCheck}
                      name="subject"
                      onChange={handleInputChange}
                      className={styles['box-text']}
                    />
                  </div>
                  <div className={styles['long-input']}>
                    <Typography
                      content={'Message'}
                      variant={'displayXS30'}
                      className={styles['box-text']}
                    />
                    <TextField
                      data-testid="message"
                      fullWidth
                      helperText={messageError && !firstCheck ? 'Please enter a message' : ''}
                      error={messageError && !firstCheck}
                      name="message"
                      multiline
                      minRows={3}
                      onChange={handleInputChange}
                      className={styles['box-text']}
                    />
                  </div>
                  <Grid container>
                    <Grid item xs={12} xl={12} className={styles['button-container']}>
                      <Button
                        data-testid="submit-button"
                        onClick={submitForm}
                        className={styles['submit-button']}
                      >
                        <Typography content={'Send message'} />
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Section>
      </>
    )
  )
}

export { EmailForm }
