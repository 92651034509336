import { useMemoizedContentGetter } from 'utils/contentful'
import { object } from 'yup'
import { carouselItemSchema } from 'constants/validation-types'
import Section from 'components/Section'
import { Carousel } from 'components/Carousels'
import styles from './TestimonialFullBackgroundCarousel.module.scss'
import { Typography, MediaImage } from 'components/Contentful'
import { Grid, Box } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import SlideOnVisible from 'components/SlideOnVisible'

let contentSchema = object({
  carousel: object({
    carouselItems: carouselItemSchema,
  }),
})

function Card({
  item: {
    fields: { cardImage, cardTitleText, cardText },
  },
  priority,
}) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse', lg: 'row' }}
      justifyContent={{ xs: 'flex-end', lg: 'space-between' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
    >
      {isMobile ? (
        <>
          <Grid item xs={12}>
            <Grid item xs={12} className={styles['text-container']}>
              <Grid container direction="row" className={styles['card-text-grid']}>
                <Typography
                  className={styles['carousel-item-quote']}
                  content={quote}
                  fontWeight="regular"
                  variant="bodyS30"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={styles['image-container']}>
              <MediaImage content={cardImage} priority={priority} />
            </div>
          </Grid>
          <Grid item xs={12} className={styles['text-container']}>
            <Grid container direction="row" className={styles['card-text-grid']}>
              <Grid item xs={12}>
                <Typography
                  className={styles['carousel-item-title']}
                  content={cardTitleText}
                  fontWeight="bold"
                  variant={isMobile ? 'displayS30' : 'displayM20'}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} lg={3}>
            <SlideOnVisible
              scrollOnMobile={false}
              duration={1200}
              timeout={300}
              easing={'cubic-bezier(.90,1,.90,.5)'}
            >
              <div className={styles['image-container']}>
                <MediaImage content={cardImage} priority={priority} />
              </div>
            </SlideOnVisible>
          </Grid>
          <Grid item xs={12} lg={8} className={styles['text-container']}>
            <Grid container direction="row" className={styles['card-text-grid']}>
              <SlideOnVisible
                scrollOnMobile={false}
                duration={800}
                timeout={100}
                easing={'cubic-bezier(.75,1,.83,.67)'}
              >
                <Grid item xs={12}>
                  <Typography
                    className={styles['carousel-item-title']}
                    content={cardTitleText}
                    fontWeight="bold"
                    variant={isMobile ? 'displayS30' : 'displayM20'}
                  />
                </Grid>
              </SlideOnVisible>
              <SlideOnVisible
                scrollOnMobile={false}
                duration={900}
                timeout={0}
                easing={'cubic-bezier(.75,1,.83,.67)'}
              >
                <Grid item xs={12}>
                  <Typography
                    className={styles['carousel-item-quote']}
                    content={quote}
                    fontWeight="regular"
                    variant="bodyS30"
                  />
                </Grid>
              </SlideOnVisible>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

function TestimonialFullBackgroundCarousel({ content }) {
  let memoizedContent = useMemoizedContentGetter(content, ['carousel', 'backgroundConfigJson'])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'TestimonialFullBackgroundCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }
  const {
    carousel: { carouselItems },
    backgroundConfigJson: { jsonContent },
  } = memoizedContent
  const backgroundObjectPosition = jsonContent?.backgroundObjectPosition ?? 'right'
  const backgroundObjectPositionMobile = jsonContent?.backgroundObjectPositionMobile ?? 'bottom'
  return (
    <Section
      className={styles['section']}
      contain={false}
      data-narrow={true}
      backgroundObjectPosition={
        isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
      }
      showPattern={jsonContent?.showPattern}
      variant={jsonContent?.background ?? 'bgDefault'}
    >
      <Box className={styles['fullbg-content']} component="div">
        <Carousel
          className={styles['flat-carousel-wrapper']}
          carouselItems={carouselItems}
          itemComponent={Card}
          withIndicators={isMobile}
          variant="standard"
          idPrefix="full-bg-testimonial-carousel"
        />
      </Box>
    </Section>
  )
}

export { TestimonialFullBackgroundCarousel }
