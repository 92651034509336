import { Box } from '@achieve/sunbeam'
import { Typography } from 'components/Contentful'
import styles from './Breadcrumb.module.scss'
import { ArrowLeft } from 'react-feather'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'

export const Breadcrumb = ({ content }) => {
  let breadcrumbText = ''

  if (content instanceof String) {
    breadcrumbText = content
  }
  if (content instanceof Object) {
    breadcrumbText = content?.textContent?.content[0]?.content[0]?.value
  }

  return (
    <Box
      paddingTop={3}
      display={'flex'}
      alignItems={'center'}
      gap={1}
      className={styles.breadcrumb}
    >
      <AchieveLink href="/about/press" className={styles.link}>
        <Box className={styles['content-icon']}>
          <ArrowLeft className={styles.icon} />
          <Typography
            content={breadcrumbText}
            className={styles.text}
            variant="displayXS30"
            fontWeight="bold"
          />
        </Box>
      </AchieveLink>
    </Box>
  )
}
