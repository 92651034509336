import { useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, Typography } from 'components/Contentful'
import Section from 'components/Section'
import styles from './HomeHeroV2.module.scss'
import { Grid, Box, Link } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import useIsSsr from 'hooks/useIsSsr'

function HomeHeroV2({ content }) {
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const isMobile = isSsr ? 'true' : mqHookResults

  const {
    title,
    subTitle,
    background,
    button,
    link,
    eyebrow,
    backgroundConfigJson: { jsonContent },
  } = useMemoizedContentGetter(content, [
    'title',
    'subTitle',
    'background',
    'button',
    'link',
    'eyebrow',
    'backgroundConfigJson',
  ])
  const backgroundObjectPosition = jsonContent?.backgroundObjectPosition ?? 'right'
  const backgroundObjectPositionMobile = jsonContent?.backgroundObjectPositionMobile ?? 'bottom'

  let contentDirection = 'row'
  let mobileContentDirection = 'column'

  if (backgroundObjectPosition === 'left') {
    contentDirection = 'row-reverse'
  }

  if (backgroundObjectPositionMobile === 'top') {
    mobileContentDirection = 'column-reverse'
  }

  const mobilePadding = jsonContent?.mobilePadding ?? 'bottom'

  return (
    <Section
      className={styles['section']}
      data-testid="closer-fullbg-section"
      data-narrow={true}
      backgroundImageContent={
        isMobile && background?.mobileMediaContent
          ? background?.mobileMediaContent
          : background?.mediaContent
      }
      backgroundPriority={true}
      backgroundImageAlt={background?.mediaAltText}
      showPattern={jsonContent?.showPattern}
      backgroundObjectPosition={
        isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
      }
      variant={jsonContent?.background ?? 'bgDefault'}
    >
      <Box className={classNames(styles['fullbg-content'], styles[mobilePadding])} component="div">
        <Grid
          container
          direction={isMobile ? mobileContentDirection : contentDirection}
          alignItems="center"
          data-testid="closer-fullbg-outer-grid-container"
        >
          {/* The content grid item */}
          <Grid
            item
            lg={5}
            className={styles['grid-item-text']}
            data-testid="closer-fullbg-grid-item-text"
          >
            <div
              className={classNames(
                styles['text-container'],
                ...[jsonContent?.textAlign == 'right' && styles['text-padding-left']]
              )}
              data-container-color={jsonContent?.contentBackground || 'default'}
              data-testid="closer-fullbg-text-container"
            >
              {eyebrow && (
                <Eyebrow
                  content={eyebrow?.textContent}
                  variant={isMobile ? 'displayS10' : 'displayS20'}
                  fontWeight="medium"
                  component="h1"
                  className={classNames(
                    styles['eyebrow'],
                    styles[`font-${eyebrow?.fontColor.toLowerCase()}`]
                  )}
                />
              )}
              {title && (
                <Typography
                  content={title?.textContent}
                  variant={isMobile ? 'displayM10' : 'displayL10'}
                  fontWeight="bold"
                  className={classNames(
                    styles['title'],
                    styles[`font-${title?.fontColor.toLowerCase()}`]
                  )}
                />
              )}
              {subTitle && (
                <Typography
                  content={subTitle?.textContent}
                  variant={'displayS10'}
                  className={classNames(
                    styles['sub-title'],
                    styles[`font-${subTitle?.fontColor.toLowerCase()}`]
                  )}
                />
              )}
              {link && (
                <Link
                  underline="hover"
                  fontWeight="bold"
                  variant="displayS10"
                  href={link?.linkHref}
                >
                  <Typography
                    content={link?.linkText}
                    fontWeight="bold"
                    className={styles['text-link']}
                    variant="displayS10"
                  />{' '}
                  <ArrowRight className={styles['svg-link']} />
                </Link>
              )}
              {button && (
                <LinkButton
                  track={{
                    nav_link_section: 'Home Hero',
                    click_type: 'Click Button',
                  }}
                  typographicOptions={{
                    variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                    fontWeight: 'bold',
                  }}
                  variant="contained"
                  color="secondary"
                  content={button}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}

export { HomeHeroV2 }
