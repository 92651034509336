import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import {
  Select,
  TextField,
  Box,
  Grid,
  Link,
  MenuItem,
  Button as SunbeamButton,
} from '@achieve/sunbeam'
import { Typography } from 'components/Contentful/Typography'
import { useViewportLargerThan, useViewportSmallerThan } from 'utils/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './Filter.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useRouter } from 'next/router'
import { getBaseURL, getFilterUrlByValue } from 'utils/shared/filter'

function Filter({
  jsonContent,
  filterBy,
  withKeywords,
  withUrl,
  setFilterBy,
  setKeywords,
  config,
}) {
  const [keywordsValue, setKeywordsValue] = useState('')
  const router = useRouter()
  const [paramsUtm, setParamsUtm] = useState({})

  useEffect(() => {
    if (router.query) {
      const params = router.query
      let utmSource = {}
      for (const property in params) {
        if (property.includes('utm')) {
          utmSource[property] = params[property]
        }
      }
      setParamsUtm(utmSource)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const handleChange = (event) => {
    const val = event.target.value
    setFilterBy(val)
    if (withKeywords) {
      if (withUrl) {
        const filterURl = getFilterUrlByValue(val, jsonContent)
        router.push(
          {
            pathname: `/${getBaseURL(config)}/${filterURl !== '*' ? filterURl : ''}`,
            query: paramsUtm,
          },
          null,
          {
            shallow: true,
            scroll: false,
          }
        )
      } else {
        router.push(
          { pathname: `/${getBaseURL(config)}/${val !== '*' ? val : ''}`, query: paramsUtm },
          null,
          {
            shallow: true,
            scroll: false,
          }
        )
      }
    }
  }
  const isMobile = useViewportSmallerThan('lg')
  const isMedium = useViewportLargerThan('md')

  function ClearFilters() {
    return (
      <>
        <Grid className={styles['clear-filter']}>
          <Link
            variant="text"
            onClick={() => {
              setFilterBy('*')
              setKeywords(keywordsValue)
              if (withKeywords) {
                router.push({ pathname: `/${getBaseURL(config)}`, query: paramsUtm }, null, {
                  shallow: true,
                  scroll: false,
                })
              }
              setKeywordsValue('')
            }}
          >
            Clear filters
          </Link>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid className={styles['filter-container']}>
        <Grid className={styles['filter-grid']}>
          {withKeywords && (
            <Grid className={withKeywords ? styles['filter-item-full'] : styles['filter-item']}>
              <Typography
                className={styles['label']}
                content="Search for keywords"
                variantOverride={{ [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA' }}
              />
              <Grid item xs={4} lg={4}>
                <Box className={styles['box']}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      setKeywords(keywordsValue)
                    }}
                  >
                    <FormControl variant="outlined" className={styles['form-control']} fullWidth>
                      <TextField
                        onChange={(e) => {
                          setKeywordsValue(e.target.value)
                        }}
                        className={styles['keyword-input']}
                        variant="outlined"
                        value={keywordsValue}
                        placeholder="Search for keywords"
                      />
                    </FormControl>
                  </form>
                </Box>
              </Grid>
              {(withKeywords && !isMobile) || (isMedium && isMobile) ? ClearFilters() : null}
            </Grid>
          )}
          <Grid className={withKeywords ? styles['filter-item-full'] : styles['filter-item']}>
            <Typography
              className={styles['label']}
              content={jsonContent.label}
              variantOverride={{ [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA' }}
            />
            <Grid item xs={4} lg={4}>
              <Box className={styles['box']}>
                <FormControl variant="outlined" className={styles['form-control']} fullWidth>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    value={filterBy}
                    onChange={handleChange}
                  >
                    {jsonContent.options.map((menuItem, idx) => {
                      return (
                        <MenuItem key={idx} value={menuItem.value}>
                          <Typography
                            content={menuItem.label}
                            variantOverride={{
                              [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA',
                            }}
                          />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {withKeywords && (
            <SunbeamButton
              className={styles['button']}
              onClick={() => {
                setKeywords(keywordsValue)
              }}
            >
              <Typography
                className={styles['button-typography']}
                content={isMedium && isMobile ? `Filter` : `Filter Results`}
                fontWeight="bold"
                variant="displayS11CTA"
              />
            </SunbeamButton>
          )}
        </Grid>
        {withKeywords && isMobile && !isMedium ? ClearFilters() : null}
      </Grid>
    </>
  )
}

export { Filter }
