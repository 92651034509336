/**
 * Reducer function for filtering and updating state based on action type and payload
 *
 * @param {object} state - The current state of the application
 * @param {object} action - The action object containing the type and payload information
 * @returns {object} - The updated state of the application based on the action type and payload
 */
const filterReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      // Initialize the state with the page and title values from the action object
      return { ...state, page: action.page, title: action.title }
    case 'SET_PAGE':
      return { ...state, page: action.payload }
    case 'SET_TITLE':
      return { ...state, title: action.payload }
    case 'SET_ARTICLES':
      return { ...state, articlesObject: action.payload }
    case 'SET_KEYWORDS':
      return { ...state, keywordsFilter: action.payload }
    case 'SET_FILTER_BY':
      return { ...state, filterBy: action.payload }
    default:
      return state
  }
}

/**
 * Returns the value for a given URL option in a JSON object
 *
 * @param {string} url - The URL to parse and search for a matching option
 * @param {object} jsonContent - The JSON object containing a list of options to search through
 * @returns {string} - The value of the matching option, or * if no match is found
 */
const getFilterValueByUrl = (url, jsonContent) => {
  // Split the URL by / and retrieve the third element
  url = url.split('/')[3]

  if (!url) return '*'

  // Find the option in the JSON object that matches the URL
  const urlOption = jsonContent.options.find((option) => {
    return option.url == url
  })

  // If a matching option was found, return its value, otherwise return *
  return urlOption?.value || '*'
}

/**
 * Returns the URL for a given value in a JSON object
 *
 * @param {string} value - The value to search for in the JSON object
 * @param {object} jsonContent - The JSON object containing a list of options to search through
 * @returns {string} - The URL of the matching option
 */
const getFilterUrlByValue = (value, jsonContent) => {
  if (!jsonContent?.options?.length) return
  const urlOption = jsonContent.options.find((option) => {
    return option.value == value
  })
  return urlOption?.url
}

/**
 * Returns the base URL for a given configuration
 *
 * @param {object} config - The configuration object containing the JSON content type
 * @returns {string} - The base URL for the given JSON content type
 */
const getBaseURL = (config) => {
  if (config?.jsonContent?.type == 'pressStory') return 'about/press'
  if (config?.jsonContent?.type == 'corporateblog') return 'about/blog'
  if (config?.jsonContent?.type == 'consumerblog') return 'consumer-name'
  return 'learn'
}

/**
 * Returns the content type for a given configuration
 *
 * @param {object} config - The configuration object containing the JSON content type
 * @returns {string} - The content type for the given JSON content type
 */
const getContentType = (config) => {
  if (config?.jsonContent?.type == 'corporateblog') return 'corporateBlog'
  if (config?.jsonContent?.type == 'consumerblog') return 'consumerBlog'
  return 'learn'
}

export { filterReducer, getBaseURL, getContentType, getFilterUrlByValue, getFilterValueByUrl }
