import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import styles from './PartnersForm.module.scss'
import { get as _get } from 'lodash-es'
import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { Box, TextField, Grid } from '@achieve/sunbeam'
import { useReducer } from 'react'
import { Button } from 'components/Button'
import useTheme from 'hooks/useTheme'

export function PartnersForm({ content }) {
  const theme = useTheme()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const memoizedContent = useMemoizedContentGetter(content, [
    'header',
    'title',
    'subtitle',
    'message_successfully',
  ])

  const { header, title, subtitle, message_successfully } = memoizedContent

  const reducerFunction = (state, action) => {
    switch (action.type) {
      case 'setField':
        return { ...state, [action.fieldName]: action.fieldValue }
      case 'setError':
        return { ...state, [action.fieldName]: action.hasError }
      case 'setSent':
        return {
          ...state,
          sent: true,
          sentTitle: _get(message_successfully?.textContent, 'content[0].content[0].value'),
        }
      case 'setSentError':
        return { ...state, sent: true, sentTitle: 'Oops! Something went wrong!' }
      case 'setFirstCheck':
        return { ...state, firstCheck: false }
      case 'resetState':
        return { ...state, ...action.newState }
      default:
        return state
    }
  }

  const [
    {
      first_name,
      last_name,
      company,
      email,
      title_name,
      phone,
      message,
      firstCheck,
      sent,
      sentTitle,
      first_name_error,
      last_name_error,
      company_error,
      email_error,
      phone_error,
      message_error,
      message_error_limit,
    },
    dispatch,
  ] = useReducer(reducerFunction, {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    title_name: '',
    phone: '',
    message: '',
    firstCheck: true,
    sent: false,
    sentTitle: '',
    first_name_error: true,
    last_name_error: true,
    company_error: true,
    email_error: true,
    phone_error: true,
    message_error: true,
    message_error_limit: true,
  })

  const validationRegexObject = {
    first_name: (value) => {
      return dispatch({
        type: 'setError',
        fieldName: 'first_name_error',
        hasError: !/^[\s\S,.'-]+$/i.test(value),
      })
    },
    last_name: (value) => {
      return dispatch({
        type: 'setError',
        fieldName: 'last_name_error',
        hasError: !/^[\s\S,.'-]+$/i.test(value),
      })
    },
    company: (value) => {
      return dispatch({
        type: 'setError',
        fieldName: 'company_error',
        hasError: !/^[\s\S,.'-]+$/i.test(value),
      })
    },
    email: (email) => {
      return dispatch({
        type: 'setError',
        fieldName: 'email_error',
        hasError: !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email),
      })
    },
    phone: (phone) => {
      return dispatch({
        type: 'setError',
        fieldName: 'phone_error',
        hasError:
          !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g.test(
            phone
          ),
      })
    },
    message: (message) => {
      return (
        dispatch({
          type: 'setError',
          fieldName: 'message_error',
          hasError: !/^[\s\S,.'-]+$/i.test(message),
        }),
        dispatch({
          type: 'setError',
          fieldName: 'message_error_limit',
          hasError: !/^[\s\S]{1,3000}$/g.test(message),
        })
      )
    },
  }

  const handleInputChange = (event) => {
    dispatch({
      type: 'setField',
      fieldName: event.target.name,
      fieldValue: event.target.value,
    })
    if (
      ['first_name', 'last_name', 'company', 'email', 'message', 'phone'].includes(
        event.target.name
      )
    ) {
      validationRegexObject[event.target.name](event.target.value)
    }
  }

  const submitForm = () => {
    if (firstCheck) {
      dispatch({ type: 'setFirstCheck' })
    }
    sendMail()
  }

  const sendMail = async () => {
    if (
      !first_name_error &&
      !last_name_error &&
      !company_error &&
      !email_error &&
      !message_error &&
      !message_error_limit &&
      !phone_error
    ) {
      const body = {
        first_name: first_name,
        last_name: last_name,
        company: company,
        email: email,
        title: title_name,
        message: message,
        phone: phone,
      }

      fetch('/api/salesforce/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 200) {
          dispatch({ type: 'setSent' })
        } else {
          dispatch({ type: 'setSentError' })
        }
      })
    }
  }

  return (
    <Section
      className={styles['partners-section']}
      contain={false}
      id="partners-section"
      backgroundColor={theme?.sb?.colors?.neutral?.grey?.[8]}
    >
      {sent ? (
        <Box>
          <Typography
            content={sentTitle}
            fontWeight="medium"
            variant={'displayS20'}
            className={styles['sent-title']}
          />
        </Box>
      ) : (
        <>
          <Box className={styles['partners-section-info']}>
            <Eyebrow
              content={header?.textContent}
              variant="displayXS30"
              fontWeight="bold"
              className={styles['partners-section-header']}
            />
            <Typography
              content={title?.textContent}
              variant={isMobile ? 'displayS30' : 'displayM20'}
              className={styles['partners-section-title']}
              fontWeight={'bold'}
            />
            <Typography
              content={subtitle?.textContent}
              variant={isMobile ? 'bodyS30' : 'bodyS40'}
              className={styles['partners-section-subtitle']}
              fontWeight={'regular'}
            />
          </Box>
          <Box className={styles['partners-section-form']}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'First Name'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  error={first_name_error && !firstCheck}
                  helperText={first_name_error && !firstCheck ? 'Please enter a first name' : ''}
                  name="first_name"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'Last Name'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  error={last_name_error && !firstCheck}
                  helperText={last_name_error && !firstCheck ? 'Please enter a last name' : ''}
                  name="last_name"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'Company'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  error={company_error && !firstCheck}
                  helperText={company_error && !firstCheck ? 'Please enter a company name' : ''}
                  name="company"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'Company Email'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  error={email_error && !firstCheck}
                  helperText={email_error && !firstCheck ? 'Please enter a company email' : ''}
                  name="email"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'Title'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  name="title_name"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  content={'Phone Number'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-input']}
                  fullWidth
                  error={phone_error && !firstCheck}
                  helperText={phone_error && !firstCheck ? 'Please enter a phone number' : ''}
                  name="phone"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  content={'Message'}
                  variant={'displayXS30'}
                  className={styles['box-text']}
                />
                <TextField
                  className={styles['box-textarea']}
                  fullWidth
                  error={(message_error || message_error_limit) && !firstCheck}
                  helperText={
                    message_error && !firstCheck
                      ? 'Please enter a message'
                      : message_error_limit && !firstCheck
                      ? 'Your message exceeds the 3000 character count limit'
                      : ''
                  }
                  name="message"
                  multiline
                  minRows={3}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12} textAlign={'center'}>
                <Button onClick={submitForm} className={styles['submit-button']}>
                  <Typography content={'Send message'} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Section>
  )
}
