import React from 'react'

import { Typography, MediaImage } from 'components/Contentful'
import { Button, Grid, Box } from '@achieve/sunbeam'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'

import Section from 'components/Section'

import useTheme from 'hooks/useTheme'
import SlideOnVisible from 'components/SlideOnVisible'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import styles from './StoryBlocks.module.scss'
import { Eyebrow } from 'components/Eyebrow'

export const StoryBlocks = ({ content }) => {
  const theme = useTheme()
  const { eyebrow, title, storyBlockGrid } = useMemoizedContentGetter(content, [
    'eyebrow',
    'title',
    'storyBlockGrid',
  ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  return (
    <Section
      backgroundColor={theme?.sb?.colors?.neutral?.white}
      data-testid="story-blocks-section"
      disableGutters
      contain={false}
      className={styles['story-blocks-container']}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={10}
          md={10}
          lg={10}
          xl={8}
          xxl={6}
          xs={11}
          justifyContent="space-around"
          data-testid="mediabox-title"
        >
          <Eyebrow
            data-testid="story-block-eyebrow"
            content={eyebrow?.textContent}
            variant="displayXS30"
            fontWeight="bold"
            component="h3"
          />
          <Typography
            data-testid="member-stories-story-block-title"
            className={styles['title']}
            content={title?.textContent}
            variant={isMobile ? 'displayS30' : 'displayM20'}
            fontWeight="bold"
            component="h2"
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-evenly" data-testid="media-box-grid">
        {storyBlockGrid &&
          storyBlockGrid.gridItems.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  order={{ xs: index + 1, md: index % 2 ? index + 2 : index + 1 }}
                >
                  <div className={styles['block-img']}>
                    <MediaImage
                      data-testid={`block-img-${index}`}
                      content={card.fields.image}
                      alt={card.altText}
                      layout="fill"
                      objectFit="cover"
                      width
                      height
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  order={{ xs: index + 2, md: index % 2 ? index : index + 1 }}
                  className={styles['content-box']}
                  data-testid={`content-box-item-${index}`}
                >
                  <Box
                    className={styles['box-media-text']}
                    data-testid={`story-block-box-${index}`}
                  >
                    <SlideOnVisible
                      scrollOnMobile={false}
                      duration={700}
                      timeout={0}
                      easing={'cubic-bezier(.75,1,.83,.67)'}
                    >
                      <div className={styles['text-center']}>
                        <Typography
                          data-testid={`story-block-title-${index}`}
                          fontWeight="bold"
                          variant="displayS30"
                          style={{ marginBottom: '24px' }}
                          content={card.fields.title}
                        />
                        <Typography
                          data-testid={`story-block-text-${index}`}
                          style={{ marginBottom: '24px', justifyContent: 'space-between' }}
                          variant="bodyS30"
                          content={card.fields.text}
                        />
                        <div className={styles['button-wrapper']}>
                          <Button
                            data-testid={`story-block-button-${index}`}
                            href={card.fields.link.fields.linkHref}
                            className={styles['link-button']}
                            endIcon={<ArrowForwardIcon />}
                          >
                            <Typography
                              content={card.fields.link.fields.linkText}
                              fontWeight="bold"
                              variant={isMobile ? 'displayXS30' : 'displayS11CTA'}
                            />
                          </Button>
                        </div>
                      </div>
                    </SlideOnVisible>
                  </Box>
                </Grid>
              </React.Fragment>
            )
          })}
      </Grid>
    </Section>
  )
}
