import Section from 'components/Section'
import styles from './SmsDisclosures.module.scss'
import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'

function SmsDisclosures({ content }) {
  const memoizedContent = useMemoizedContentGetter(content, ['title', 'body'])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const { title, body } = memoizedContent

  return (
    <Section
      className={styles['sms-disclosures-section']}
      childContainerClassName={styles['sms-disclosures-container']}
    >
      {title && (
        <Typography
          className={styles['sms-disclosures-title']}
          content={title.textContent}
          variant={isMobile ? 'displayM20' : 'displayL10'}
        />
      )}
      {body && (
        <Typography
          className={styles['table-custom']}
          variantOverride={{
            [BLOCKS.HEADING_2]: isMobile ? 'displayS30' : 'displayM20',
            [BLOCKS.HEADING_3]: isMobile ? 'displayS10' : 'displayS30',
            [BLOCKS.HEADING_4]: isMobile ? 'displayXS30' : 'displayS10',
            [BLOCKS.PARAGRAPH]: 'bodyS30',
          }}
          content={body.textContent}
          styleOverride={{
            [BLOCKS.HEADING_2]: { marginBottom: '16px' },
            [BLOCKS.HEADING_3]: { marginBottom: '36px', marginTop: '36px' },
            [BLOCKS.HEADING_4]: { marginBottom: '36px', marginTop: '36px' },
            [BLOCKS.PARAGRAPH]: { marginBottom: '16px' },
          }}
        />
      )}
    </Section>
  )
}

export { SmsDisclosures }
