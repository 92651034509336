import { Typography } from 'components/Contentful'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { Grid } from '@achieve/sunbeam'
import { GridHandler } from 'components/ContentHandlers'

export function CardGrid({ content }) {
  const { title, subtitle, grid } = useMemoizedContentGetter(content, ['title', 'subtitle', 'grid'])
  return (
    <Section>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {title && <Typography content={title.textContent} />}
        {subtitle && <Typography content={subtitle.textContent} />}
        {grid && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <GridHandler gridContents={grid.gridItems} columns={grid.columns} />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}
