import styles from './PhoneAndNumbers.module.scss'
import { Grid, Box } from '@achieve/sunbeam'
import Section from 'components/Section'
import { CardPhone } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import useTheme from 'hooks/useTheme'
import classNames from 'classnames'

function PhoneAndNumbers({ content }) {
  const { title, subtitle, grid, theme } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'grid',
    'theme',
  ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const fieldName = content.fields.fieldName

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(fieldName)

  return (
    <Section
      backgroundColor={backgroundColor}
      className={!breakline ? styles['section'] : styles['section-breakline']}
    >
      <Grid
        container
        justifyContent="center"
        data-testid="phone-number-grid-container"
        className={styles['container-grid']}
      >
        <div className={styles['grid-wrapper']}>
          <TitleDivider
            data-testid="phone-number-title-divider"
            className={styles['title-divider-wrapper']}
            title={title}
            subtitle={subtitle}
            isMobile={isMobile}
            variants={variant}
            component="h2"
          />
        </div>
        <Grid
          item
          sm={10}
          md={8}
          lg={12}
          data-testid="phone-number-grid-card-container"
          className={classNames(styles['card-grid'], { [styles['breakline']]: breakline })}
        >
          {grid?.gridItems.map((item, idx) => {
            const card = (
              <Box
                data-testid={`phone-number-grid-card-${idx}`}
                className={styles['phone-number-grid-card']}
                key={`card-${idx}`}
              >
                <CardPhone content={item} isMobile={isMobile} breakline={breakline} />
              </Box>
            )
            const separator = (
              <Box
                data-testid={`phone-number-grid-separator-${idx}`}
                className={styles['phone-number-grid-separator']}
                key={`separator-${idx}`}
              ></Box>
            )
            if (idx > 0) {
              return [separator, card]
            }
            return card
          })}
        </Grid>
      </Grid>
    </Section>
  )
}

export { PhoneAndNumbers }
