import React from 'react'

import { Typography, TypographyLongForm, MediaImage } from 'components/Contentful'
import { Box } from '@achieve/sunbeam'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import styles from './StoryBlocksAuthor.module.scss'
import SlideOnVisible from 'components/SlideOnVisible'

export const StoryBlocksAuthor = ({ content }) => {
  const { StoryBlocksAuthorGrid } = useMemoizedContentGetter(content, ['StoryBlocksAuthorGrid'])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  return (
    <Section disableGutters contain={false} className={styles['story-blocks-container']}>
      <Box className={styles['story-blocks-grid']}>
        {StoryBlocksAuthorGrid &&
          StoryBlocksAuthorGrid.gridItems.map((card, index) => {
            return (
              <Box
                key={index}
                className={styles['story-blocks-item']}
                data-align-left={!(index % 2 == 0)}
              >
                <Box className={styles['story-blocks-item-img']}>
                  <SlideOnVisible
                    duration={700}
                    timeout={300}
                    easing={'cubic-bezier(.75,1,.83,.67)'}
                  >
                    <div className={styles['block-img']}>
                      {isMobile ? (
                        <MediaImage
                          content={card?.fields?.image}
                          alt={card?.altText}
                          layout="fixed"
                          objectFit="cover"
                          width={340}
                          height={360}
                        />
                      ) : (
                        <MediaImage
                          content={card?.fields?.image}
                          alt={card?.altText}
                          layout="fill"
                          objectFit="cover"
                          width
                          height
                          objectPosition="center top"
                        />
                      )}
                    </div>
                  </SlideOnVisible>
                </Box>
                <Box className={styles['story-blocks-item-content']}>
                  <SlideOnVisible
                    duration={1150}
                    timeout={300}
                    easing={'cubic-bezier(.75,1,.83,.67)'}
                  >
                    <div>
                      <Typography
                        content={card?.fields?.author}
                        variant="displayXS30"
                        fontWeight="bold"
                        className={styles['story-blocks-item-author']}
                      />
                      <Typography
                        content={card?.fields?.title}
                        variant={isMobile ? 'displayS30' : 'displayM20'}
                        fontWeight="bold"
                        className={styles['story-blocks-item-title']}
                      />
                      {card?.fields?.text?.content?.map((text, cardIndex) => {
                        return (
                          <TypographyLongForm
                            key={cardIndex}
                            content={text}
                            variant={
                              cardIndex === 0 ? (isMobile ? 'bodyS30' : 'bodyS40') : 'bodyS30'
                            }
                            fontWeight="regular"
                          />
                        )
                      })}
                    </div>
                  </SlideOnVisible>
                </Box>
              </Box>
            )
          })}
      </Box>
    </Section>
  )
}
