import styles from './ValueProps.module.scss'
import { Grid, Box } from '@achieve/sunbeam'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton, Typography, MediaImage } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import classNames from 'classnames'
import useTheme from 'hooks/useTheme'
import { Carousel } from 'components/Carousels'
import { Eyebrow } from 'components/Eyebrow'

function Card({
  item: {
    fields: { cardImage, cardTitleText, cardText },
  },
  priority,
}) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse' }}
      justifyContent={{ xs: 'flex-end' }}
      alignItems={{ xs: 'left' }}
    >
      <Grid item xs={6}>
        <div className={styles['image-container']}>
          <MediaImage
            layout={'fixed'}
            content={cardImage}
            priority={priority}
            width={70}
            height={70}
          />
        </div>
        <Typography
          className={styles['carousel-item-title']}
          content={cardTitleText}
          fontWeight="bold"
          variant={isMobile ? 'displayS30' : 'displayM20'}
        />
        <Typography
          className={styles['carousel-item-quote']}
          content={quote}
          fontWeight="regular"
          variant="bodyS30"
        />
      </Grid>
    </Grid>
  )
}

function ValueProps({ content }) {
  const idContent = content?.sys?.id
  const { title, eyebrow, subtitle, grid, theme, linkButton, backgroundConfigJson } =
    useMemoizedContentGetter(content, [
      'title',
      'eyebrow',
      'subtitle',
      'grid',
      'theme',
      'linkButton',
      'backgroundConfigJson',
    ])
  const jsonContent = backgroundConfigJson?.jsonContent || {}
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const fieldName = content.fields.fieldName

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(fieldName)
  const containerGrid = !['aboutUsCulture', 'partnersValueProps'].includes(fieldName)

  const itemsByrow = jsonContent?.itemsByrow || 3
  const carouselOnMobile = jsonContent?.carouselOnMobile || false
  const dividerOnMobile = jsonContent?.dividerOnMobile || false
  const dividerToggle =
    jsonContent?.dividerToggle || jsonContent?.dividerToggle == false
      ? jsonContent?.dividerToggle
      : true

  const gridItems = (dividerOnMobile = false) => {
    return (
      <>
        {grid?.gridItems.map((item, idx) => {
          const card = (
            <Grid
              item
              lg={3.5}
              className={classNames(
                (idx + 1) % itemsByrow == 0 || !dividerToggle ? '' : styles['grid-item'],
                idx + 1 > itemsByrow && styles['spacing-grid-item']
              )}
              key={`card-${idx}`}
            >
              <Box
                data-testid={`value-prop-grid-${idContent}-card-${idx}`}
                className={styles['value-prop-grid-card']}
              >
                <CardValueProp
                  content={item}
                  isMobile={isMobile}
                  breakline={breakline}
                  dividerOnMobile={dividerOnMobile}
                  pos={idx}
                  className={styles['card-container']}
                />
              </Box>
            </Grid>
          )

          return card
        })}
      </>
    )
  }

  return (
    <Section
      backgroundColor={!jsonContent?.background ? backgroundColor : null}
      className={classNames(styles['section'], { [styles['breakline']]: breakline })}
      variant={jsonContent?.background ?? 'bgDefault'}
    >
      <Grid
        container
        justifyContent="center"
        data-testid={`value-prop-grid-container-${idContent}`}
        className={styles['container-grid']}
      >
        {eyebrow?.textContent && (
          <Eyebrow
            data-testid={`featured-story-eyebrow-${idContent}`}
            content={eyebrow?.textContent}
            className={styles['eyebrow']}
            variant="displayXS30"
            fontWeight="bold"
            black={(eyebrow.fontColor === 'Black').toString()}
          />
        )}
        <div className={styles['grid-wrapper']}>
          <TitleDivider
            data-testid={`value-prop-title-divider-${idContent}`}
            className={styles['title-divider-wrapper']}
            title={title}
            subtitle={subtitle}
            isMobile={isMobile}
            variants={variant}
            disableTitleTag={true}
            disableSubtitleTag={true}
          />
        </div>

        {isMobile && carouselOnMobile ? (
          //only on mobile and carouselOnMobile set to true
          <Carousel
            className={styles['flat-carousel-wrapper']}
            carouselItems={grid?.gridItems}
            itemComponent={Card}
            variant="preview-small-xs"
            idPrefix="value-props-carousel"
            withIndicators
            classNameIndicators={styles['indicators']}
            classNameActiveDot={styles['active-indicator']}
          />
        ) : containerGrid ? (
          <Grid
            container
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles['card-grid'], { [styles['breakline']]: breakline })}
          >
            {gridItems(dividerOnMobile)}
          </Grid>
        ) : (
          // only for aboutUs culture
          <Grid
            item
            sm={10}
            md={8}
            lg={12}
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles[isMobile ? 'card-grid-column' : 'card-grid'], {
              [styles['breakline']]: breakline,
            })}
          >
            {gridItems()}
          </Grid>
        )}
        {linkButton && (
          <div className={styles['button-wrapper']}>
            <LinkButton
              className={styles['button']}
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS10' },
                fontWeight: 'bold',
                'data-testid': `value-prop-button-text-${idContent}`,
                className: styles['typography'],
              }}
              color="primary"
              data-testid={`value-prop-button-${idContent}`}
              track={{
                nav_link_section: 'Value Props',
                click_type: 'Click Link',
                click_position: '0',
                click_id: 'Get Started',
                form_ss_amount: 1000,
                track_event: 'internal_campaign_click',
              }}
            />
          </div>
        )}
      </Grid>
    </Section>
  )
}

export { ValueProps }
